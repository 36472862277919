import request from '@/libs/API_common.js';
import axios from 'axios';

//智能巡店-门店分组
const baseUrl = '/store-inspect/api/store';

//获取分区树
export const getStoreTree = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/areaTree`,
    method: 'get',
    params,
  });
//新增分区
export const saveArea = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/saveArea`,
    method: 'post',
    data: params,
  });
//门店表表
export const getStoreLists = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/pageList`,
    method: 'post',
    data: params,
    noLoading: true,
  });
//删除门店分区
export const deleteStoreArea = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/deleteStoreArea`,
    method: 'get',
    params,
  });
//编辑门店
export const saveStore = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/save`,
    method: 'post',
    data: params,
  });
//删除门店
export const deleteStore = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/deleteStore`,
    method: 'get',
    params,
  });
//查看门店
export const getStoreDetail = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/detail`,
    method: 'get',
    params,
  });
//查询门店标签
export const getTagList = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/tagList`,
    method: 'get',
    params,
  });
//保存门店标签
export const saveStoreTag = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/saveTag`,
    method: 'post',
    data: params,
  });
//删除门店标签
export const deleteStoreTag = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/deleteStoreTag`,
    method: 'get',
    params,
  });
//保存门店类型
export const saveStoreType = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/saveStoreType`,
    method: 'post',
    data: params,
  });
export const getTypeList = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/typeList`,
    method: 'get',
    params,
  });

//门店批量导入
export const saveStoreUpload = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/upload`,
    method: 'post',
    data: params,
    noLoading: true,
  });

//门店批量更新
export const saveStoreAdd = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/upload/add`,
    method: 'post',
    data: params,
    noLoading: true,
  });
// 导入门店的模板
export const downStoreImportTemplate = (params) =>
  axios.get(`/om-auth/api/auth/org/store/download`, {
    params: params,
    responseType: 'blob',
  });
// 批量更新的模板
export const downStoreAddTemplate = (params) =>
  axios.get(`/om-auth/api/auth/org/store/download/add`, {
    params: params,
    responseType: 'blob',
  });
//批量更新门店大区和标签
export const batchUpdateStore = (params) =>
  request({
    url: `/om-auth/api/auth/org/store/batch`,
    method: 'post',
    data: params,
  });
//查询部门的人员
export const getStaffList = (params) =>
  request({
    url: `/om-auth/api/auth/org/staff/pageList`,
    method: 'post',
    data: params,
  });

// 获取搜索城市下拉数据
export const getCityList = (params) =>
  request({
    url: '/om-auth/api/auth/org/store/searchCondition',
    method: 'get',
    params,
  });

// 查询未分配门店list
export const getNoMatchArea = (params) =>
  request({
    url: '/om-auth/api/auth/org/store/nonMatchArea',
    method: 'get',
    params,
  });

//查询外部人员的人员列表
export const getExternalFranchiseeList = (params) =>
  request({
    url: `/om-auth/api/auth/franchisee/pageList`,
    method: 'post',
    data: params,
  });
