<template>
  <div class="img-upload">
    <div :class="{ 'content-wrapper': true, custom: hasCustomMode }">
      <div class="image-custom-background" v-if="hasCustomMode">
        <a-spin :spinning="customSpinning" style="width: 100%; height: 100%">
          <div class="custom-poster" id="posterBg">
            <div
              class="file-upload-title"
              :style="{
                left: getImageTitlePosition.left,
                top: getImageTitlePosition.top,
              }"
              v-show="isShowTitle"
            >
              {{ getCustomName }}
            </div>
            <img class="image-background" alt="back" :src="getImageBackgroundInfo.url" />
          </div>
        </a-spin>
        <div class="board-wrapper">
          <div class="switch" @click="switchNextImage">换一张</div>
          <div class="refresh" @click="switchNextImage">刷新</div>
        </div>
      </div>
      <a-upload
        :disabled="disabled"
        :accept="accept"
        :list-type="listType"
        :show-upload-list="showUploadList"
        :beforeUpload="beforeImageUpload"
        :customRequest="customImageRequest"
      >
        <div class="img-list" v-if="!$scopedSlots.uploadBtn">
          <template v-if="!hasCustomMode">
            <div
              class="img-container"
              :style="'width:' + width + ';height:' + height + ';padding:' + spacePadding"
              v-for="(item, index) in imageUrls"
              :key="index"
            >
              <img class="img" :src="item" style="object-fit: cover" />
              <span class="tip" v-if="tip">{{ tip }}</span>
              <a-space v-if="getMultiple || tip" class="options" @click.stop="() => {}">
                <a-icon type="eye" @click.stop="showPrevew(index)" />
                <a-icon type="delete" @click.stop="handleDelete(index)" v-if="!disabled" />
              </a-space>
            </div>
          </template>

          <div
            v-if="
              hasCustomMode ||
              (getMultiple && imageUrls.length < getMaxCount && !disabled) ||
              (!getMultiple && !imageUrls.length)
            "
            :style="'width:' + width + ';height:' + height"
            class="upload-btn"
          >
            <a-icon v-if="showTip" :type="isShowSpinning ? 'loading' : 'plus'" />
            <div v-if="showTip" class="ant-upload-text">
              {{ tip || '点击上传图片' }}
            </div>
          </div>
        </div>
        <template v-else>
          <slot name="uploadBtn">
            <p class="default-tips">图片建议800*400，比例为2:1，支持jpg、png格式。</p>
          </slot>
        </template>
      </a-upload>
    </div>

    <a-modal title="" :visible="prevewVisible" @cancel="handleCancel" :footer="null">
      <span style="display: inline-block">
        <img style="width: 472px" :src="prevewUrl" alt="" />
      </span>
    </a-modal>
    <canvas id="canvas" style="display: none"></canvas>
  </div>
</template>
<script>
// 调后端接口上传图片
import { uploadImage } from '@/api/api_coupon/common';
import tools from '@/pages/trade/tools';
import axios from 'axios';
import actionImages from '@/components-antd/MzUpload/images/index';
import _ from 'lodash';
import html2canvas from 'html2canvas';
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tip: {
      type: String,
      default: '',
    },
    //展示提示文字
    showTip: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    // 图片展示填充
    spacePadding: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Array],
      default: '',
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    showUploadList: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: 'picture-card',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxNum: {
      //只有 multiple 为true有效
      type: Number,
      default: Infinity,
    },
    // 限制尺寸(宽高比)
    limitDimensionsInfo: {
      type: Object,
      default: null,
    },
    imgCompress: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
    },
  },
  data() {
    return {
      ossFilePath: '',
      imageUrls: '',
      isShowSpinning: false,
      prevewVisible: false,
      prevewUrl: '',
      customSpinning: false,
      currentBackgroundIndex: 0,
      // 当前图片背景锁
      currentBackgroundLock: true,
      // 当前title是否展示
      titleVisible: false,
    };
  },

  watch: {
    value(data) {
      if (!this.getMultiple) {
        this.imageUrls = data ? [data] : [];
      } else {
        this.imageUrls = data;
      }
    },
    'config.custom.customName'(val, oldVal) {
      if (oldVal === undefined || !this.hasCustomMode || this.isLock) {
        return;
      }
      this.renderPosterBg();
    },
  },
  methods: {
    // 使用html2canvas渲染posterBg成图片
    renderPosterBg() {
      this.imageUrls = [];
      this.isShowTitle = true;
      // 打开加载中
      this.customSpinning = true;
      // 展示title
      this.titleVisible = true;
      setTimeout(async () => {
        // 获取posterBg的dom
        const posterBg = document.querySelector('#posterBg');
        // 获取posterBg的dom的宽高
        const { width, height } = posterBg.getBoundingClientRect();
        // 放大倍数
        const scale = 6;
        // 调用html2canvas来渲染
        const canvas = await html2canvas(posterBg, {
          width,
          height,
          useCORS: true,
          allowTaint: false,
          backgroundColor: null,
          scale,
        });
        // 将canvas转成图片
        canvas.toBlob((blob) => {
          const formData = new FormData();
          formData.append('file', blob);
          formData.append('type', 'image/jpeg');
          uploadImage(formData).then((res) => {
            if (res.data.status === '1') {
              const url = res.data.data;
              this.$emit('change', url);
              this.customSpinning = false;
              this.titleVisible = false;
            }
          });
        });
        // 关闭加载中
      }, 120);
    },

    // 解锁
    openImageBackgroundLock() {
      this.currentBackgroundLock = false;
    },
    // 上锁
    closeImageBackgroundLock() {
      this.currentBackgroundLock = true;
    },
    // 切换下一张图片
    switchNextImage() {
      this.openImageBackgroundLock();
      this.currentBackgroundIndex++;
      if (this.currentBackgroundIndex >= actionImages.length) {
        this.currentBackgroundIndex = 0;
      }
      // 如果自定义名字为空，不进行渲染
      if (this.getCustomName) {
        this.renderPosterBg();
      }
    },
    // 图片请求
    picRequest(url, data) {
      return axios({
        method: 'post',
        url,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    // 通过服务器上传到oss
    uploadImageToServer(file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', file.type);

      uploadImage(formData)
        .then((res) => {
          if (res.data.status == 1) {
            if (!this.getMultiple) {
              this.imageUrls = [res.data.data];
              this.$emit('change', this.imageUrls[0]);
            } else {
              this.imageUrls.push(res.data.data);
              this.$emit('change', this.imageUrls);
            }
            this.closeImageBackgroundLock();
          } else {
            this.$message({
              message: '上传失败',
              type: 'error',
            });
          }
        })
        .finally(() => {
          this.isShowSpinning = false;
        });
    },

    // 上传图片前的钩子函数
    beforeImageUpload(file) {
      return new Promise(async (resolve, reject) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          this.$message.warning('只能上传jpg/png格式图片!');
          reject(new Error('只能上传jpg/png格式图片!'));
          return;
        }
        // 检测质量
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('图片质量不能大于2M!');
          return;
        }
        // 限制尺寸
        if (this.limitDimensionsInfo) {
          try {
            await this.checkImageWH(file);
          } catch (error) {
            this.$message.warning(error);
            reject(error);
            return;
          }
        }
        this.isShowSpinning = true;
        let newFile = file;
        if (this.imgCompress) {
          newFile = await this.compressImg(file);
        }
        resolve(newFile);
      });
    },
    checkImageWH(file) {
      const that = this;
      return new Promise(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = (e) => {
          let src = e.target.result;
          const image = new Image();
          image.onload = () => {
            // if(image.width / image.height)
            if (that.limitDimensionsInfo.width && that.limitDimensionsInfo.height) {
              if (
                Number(image.width / image.height) >
                Number(that.limitDimensionsInfo.width / that.limitDimensionsInfo.height)
              ) {
                // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
                reject('图片宽高比需小于9:16');
                return;
              }
            }

            if (that.limitDimensionsInfo.scale) {
              console.log(
                that.limitDimensionsInfo.scale,
                Number(image.width / image.height),
                '---------',
              );
              if (that.limitDimensionsInfo.scale != Number(image.width / image.height)) {
                reject('上传图片不满足图片尺寸比例要求');
                return;
              }
            }
            if (that.limitDimensionsInfo.minWidth) {
              if (Number(image.width) < Number(that.limitDimensionsInfo.minWidth)) {
                reject('上传图片不满足最小分辨率要求');
                return;
              }
            }
            resolve();
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
    // 自定义的上传请求
    async customImageRequest(info) {
      this.uploadImageToServer(info.file);
    },
    // base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // 图片压缩函数
    compressImg(file) {
      console.log('压缩');
      const that = this;
      var files;
      var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2);
      var read = new FileReader();
      read.readAsDataURL(file);
      return new Promise(function (resolve, reject) {
        read.onload = function (e) {
          var img = new Image();
          img.src = e.target.result;
          img.onload = function () {
            // 默认按比例压缩
            var w = this.width;
            var h = this.height;
            // 生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            var base64;
            if (fileSize < 1) {
              // 创建属性节点
              canvas.setAttribute('width', w);
              canvas.setAttribute('height', h);
              ctx.drawImage(this, 0, 0, w, h);
              base64 = canvas.toDataURL(file['type'], 0.5);
            } else if (fileSize > 1 && fileSize < 2) {
              // 创建属性节点
              canvas.setAttribute('width', w / 1.2);
              canvas.setAttribute('height', h / 1.2);
              ctx.drawImage(this, 0, 0, w / 1.2, h / 1.2);
              base64 = canvas.toDataURL(file['type'], 0.5);
            } else {
              // 创建属性节点
              canvas.setAttribute('width', w / 2);
              canvas.setAttribute('height', h / 2);
              ctx.drawImage(this, 0, 0, w / 2, h / 2);
              base64 = canvas.toDataURL(file['type'], 0.3);
            }
            // 回调函数返回file的值（将base64编码转成file）
            files = that.dataURLtoFile(base64, file.name); // 如果后台接收类型为base64的话这一步可以省略
            resolve(files);
          };
        };
      });
    },

    //预览
    showPrevew(index) {
      this.prevewUrl = this.imageUrls[index];
      this.prevewVisible = true;
    },
    handleCancel() {
      this.prevewVisible = false;
    },
    //删除
    handleDelete(index) {
      this.imageUrls.splice(index, 1);
      if (this.getMultiple) {
        this.$emit('change', this.imageUrls);
      } else {
        this.$emit('change', '');
      }
    },
  },
  computed: {
    // 最大数量
    getMaxCount() {
      return this.config.maxNum || this.maxNum;
    },
    // 是否多选
    getMultiple() {
      return this.config.multiple || this.multiple;
    },
    // 是否显示title
    isShowTitle() {
      return !this.isLock && this.titleVisible;
    },
    //是否上锁
    isLock() {
      return this.currentBackgroundLock;
    },
    // 获得自定义名字
    getCustomName() {
      return this.config?.custom?.customName ?? '';
    },
    // 获取当前背景信息
    getImageBackgroundInfo() {
      // 获取图片列表第一个元素
      const image = this.imageUrls[0];
      // 如果没有图片则返回默认背景
      if (!image) {
        return actionImages[this.currentBackgroundIndex];
      } else {
        // 如果有图片则返回图片背景
        return {
          type: 'image',
          url: image,
        };
      }
    },
    // 获得当前图片的title定位
    getImageTitlePosition() {
      const position = this.getImageBackgroundInfo?.rect ?? {};
      return position;
    },
    // 是否拥有自定义模式
    hasCustomMode() {
      const mode = this.config?.custom ? true : false;
      return mode;
    },
    // 是否拥有上传模式
    hasDefaultMode() {
      const mode = this.config.upload !== false;
      return mode;
    },
  },
  mounted() {
    if (!this.getMultiple) {
      this.imageUrls = this.value ? [this.value] : [];
    } else {
      this.imageUrls = this.value || [];
    }
  },
};
</script>

<style lang="scss" scoped>
.img-upload {
  .content-wrapper {
    &.custom {
      display: flex;
    }
  }
  .image-custom-background {
    width: 216px;
    height: 108px;
    flex: 0 0 216px;
    margin-right: 20px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;

    &:hover {
      .board-wrapper {
        visibility: visible;
      }
    }

    .custom-poster {
      width: 216px;
      height: 108px;
      position: relative;

      .file-upload-title {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        position: absolute;
        margin-bottom: 8px;
        transform: translate(-50%, -50%);
        z-index: 1;
        word-break: break-all;
        min-height: 20px;
        max-width: 100%;
        user-select: none;
        white-space: normal;
        line-height: 1.2;
      }
      .image-background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0px;
      }
    }
    .board-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      width: 65px;
      height: 100%;
      visibility: hidden;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .switch {
      position: absolute;
      top: 0;
      right: 10px;
      z-index: 1;
      color: #fff;
      cursor: pointer;
      padding: 2px;
    }
    .refresh {
      position: absolute;
      bottom: 0;
      right: 10px;
      padding: 2px;
      z-index: 1;
      color: #fff;
      cursor: pointer;
    }
  }

  .img-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.9s;
    .img-container {
      position: relative;
      margin-right: 4px;
      padding: 4px;
      width: 150px;
      height: 150px;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      box-sizing: border-box;
      .options {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($color: #000000, $alpha: 0);
        visibility: hidden;
        transition: all 0.3s;
        i {
          font-size: 24px;
          color: transparent;
        }
      }
      &:hover {
        .options {
          background: rgba($color: #000000, $alpha: 0.3);
          visibility: visible;
          i {
            color: white;
          }
        }
      }
      .tip {
        background: rgba($color: #000000, $alpha: 0.3);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: white;
      }
    }
    .upload-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      padding: 4px;
      width: 150px;
      height: 150px;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
    }
  }
  .img {
    width: 100%;
    height: 100%;
  }
  .default-tips {
    font-size: 12px;
    color: #9a9a9a;
    margin-top: 13px;
  }

  & /deep/ .ant-upload.ant-upload-select-picture-card {
    width: auto;
    height: 100%;
    background-color: transparent;
    border: none;
    .ant-upload {
      padding: 0;
    }
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
