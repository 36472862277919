<template>
  <div class="text-wrapper">
    <img src="https://file.ynzyq.net/frontend/static/6034e5a7b956ad48d10a249fbbb80d63_24_24.png" alt="" />
    <span>{{ valueRef }}</span>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { defineProps, toRefs, computed } from 'vue';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  config: {
    type: Object,
    default: () => ({}),
  },
});

const { value: valueRef, config: configRef } = toRefs(props);
</script>

<style lang="scss" scoped>
.text-wrapper {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 8px;
  img {
    width: 12px;
    height: 13px;
    margin-right: 4px;
  }
  span,
  img {
    vertical-align: middle;
  }
}
</style>
