<template>
  <el-dialog
    custom-class="feedback-dialog"
    width="600px"
    title="意见反馈"
    append-to-body
    :visible.sync="visible"
    :close-on-press-escape="false"
    @close="onClose"
  >
    <el-form :rules="rules" ref="form" label-width="100px" :model="confirmModalRef">
      <el-form-item label="意见反馈" prop="feedbackContent">
        <el-input
          placeholder="提出您对鱼开店的优化意见与问题，以便于我们更好的优化产品。"
          type="textarea"
          :rows="4"
          maxlength="500"
          show-word-limit
          v-model="confirmModalRef.feedbackContent"
        ></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <span class="title">(限制20M以内) </span>
        <Uploader
          :config="reasonPicConfig"
          :customCheckFileType="checkImageExt"
          v-model="confirmModalRef.reasonPic"
        />
        <span class="tip">支持格式：png、jpg、jpeg、svg、psd、bmp、tif </span>
      </el-form-item>
      <el-form-item label="视频">
        <span class="title">(限制512M以内) </span>
        <Uploader
          :config="reasonVidConfig"
          :customCheckFileType="checkVideoExt"
          v-model="confirmModalRef.reasonVid"
        />
        <span class="tip">支持格式：mp4、mkv、rmvb、m4v、avi、fiv、vob </span>
      </el-form-item>
      <div class="u-flex u-row-right">
        <el-button size="small" round @click="visible = false">取 消</el-button>
        <el-button size="small" round type="primary" @click="submit" v-loading="loading">
          提交
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import StepFastForm from '@/components/FastPage/StepFastForm.vue';
import Uploader from './components/Uploader';
import { addFeedback } from '@/api/workflow/index.js';
export default {
  name: 'Feedback',
  components: { StepFastForm, Uploader },
  data() {
    return {
      reasonPicConfig: {
        type: 'imageUpload',
        listType: 'picture-card',
        maxNum: 4,
        maxSize: 20,
        multiple: true,
        btnUrl: 'https://file.ynzyq.net/frontend/static/feedback-upload-image.png'
      },
      reasonVidConfig: {
        listType: 'picture-card',
        type: 'videoUpload',
        multiple: true,
        maxNum: 4,
        maxSize: 512,
        btnUrl: 'https://file.ynzyq.net/frontend/static/feedback-upload-video.png'
      },
      confirmModalRef: {
        feedbackContent: '',
        reasonPic: [],
        reasonVid: [],
      },
      rules: {
        feedbackContent: [{ required: true, message: '请输入意见反馈', trigger: 'change' }],
      },
      loading: false,
      visible: false,
    };
  },
  methods: {
    // 判断文件后缀是否允许
    checkImageExt(file) {
      let limitType = ['png', 'jpg', 'jpeg', 'svg', 'psd', 'bmp', 'tif'];
      let noArrowExt = false;
      let fileExt = '';
      const reg = /.+\./;
      fileExt = file.name.replace(reg, '').toLowerCase();
      noArrowExt = limitType.some((ext) => {
        return ext.toLowerCase() === fileExt;
      });
      if (!noArrowExt) this.$message.warning(`只能上传${limitType.join('/')}格式!`);
      return noArrowExt;
    },
    // 判断文件后缀是否允许
    checkVideoExt(file) {
      let limitType = ['mp4', 'mky', 'rmvb', 'm4v', 'avi', 'fiv', 'vob'];
      let noArrowExt = false;
      let fileExt = '';
      const reg = /.+\./;
      fileExt = file.name.replace(reg, '').toLowerCase();
      noArrowExt = limitType.some((ext) => {
        return ext.toLowerCase() === fileExt;
      });
      if (!noArrowExt) this.$message.warning(`只能上传${limitType.join('/')}格式!`);
      return noArrowExt;
    },
    onClose() {
      this.confirmModalRef = { feedbackContent: '', reasonPic: [], reasonVid: [] };
      this.$refs.form.resetFields();
      this.visible = false;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let reasonPic = this.confirmModalRef.reasonPic.map((item) => item.url);
          let reasonVid = this.confirmModalRef.reasonVid.map((item) => item.url);
          addFeedback({
            feedbackContent: this.confirmModalRef.feedbackContent,
            images: reasonPic.join(','),
            videos: reasonVid.join(','),
          })
            .then((res) => {
              let data = JSON.parse(res.data);
              if (data.status == 0) {
                this.visible = false;
                this.$message.success('操作成功');
              } else {
                this.$message.error(res?.message ?? '提交失败，稍后重试');
              }
            })
            .finally((res) => {
              this.loading = false;
            });
        }
      });
    },
    open() {
      this.visible = true;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ {
  .feedback-dialog {
    .el-dialog__header {
      border-bottom: 1px solid #ddd;
    }
    .title,
    .tip {
      color: #726a66;
    }

    .ant-upload.ant-upload-select-picture-card {
      margin-bottom: 0 !important;
    }

    .el-textarea .el-input__count {
      background: transparent;
    }
  }
}
.u-row-right {
  border-top: 1px solid #ddd;
  padding-top: 22px;
}
</style>
