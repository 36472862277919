import request from '@/libs/API_common.js';

export const uploadImage = (params) =>
  request({
    url: '/coupon/api/v1/image/upload',
    method: 'POST',
    data: params,
  });
export const uploadFile = (params) =>
  request({
    url: '/coupon/api/v1/common/upload',
    method: 'POST',
    data: params,
  });
