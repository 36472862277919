import { instance } from '@/libs/API_MzSchool.js';

// 上传文件
export const uploadCourseFile = (params, _o) => {
  const options = {
    url: '/common/upload',
    method: 'post',
    data: params,
  };
  return instance({ ...options, ..._o });
};

// 获得阿里云上传凭证
export const createUploadVideo = (params) =>
  instance({
    url: '/common/createUploadVideo',
    method: 'get',
    params,
  });
// 刷新阿里云上传凭证
export const refreshUploadVideo = (params) =>
  instance({
    url: '/common/refreshUploadVideo',
    method: 'get',
    params,
  });
