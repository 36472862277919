// Tab分组
export const TYPE_GROUPS = [
  {
    key: 'dynamic',
    title: '动态',
    types: ['FIELD', 'CURRENT_PROCESSOR'],
  },
  {
    key: 'role',
    title: '角色',
    types: ['ROLE'],
  },
  {
    key: 'partnerStoreManager',
    title: '合伙制店长',
    types: ['ROLE'],
  },
  {
    key: 'external',
    title: '外部人员',
    types: ['FRANCHISEE', 'CONTRACTOR', 'SUPPLIER'],
  },
  {
    key: 'dept',
    title: '内部人员',
    types: ['DEPT'],
  },
];

export const reduceValues = (values, tabKey) => {
  const tab = TYPE_GROUPS.find((v) => v.key === tabKey);
  return values.filter((v) => tab.types.includes(v.type)).map((v) => v.id);
};
export const matchValue = (a, b) => {
  return a.type === b.type && a.id === b.id;
};
export const includeValue = (values, match) => {
  return values.find((v) => matchValue(v, match));
};

/**
 * 选中值
 * @param values
 * @param checkedValues   选中就是选中的值[]，删除就是删除的对象{}
 * @param checked   当前是true选中还是删除
 * @param tabKey
 * @returns {*}
 */
export const checkValues = (values, checkedValues, checked, tabKey) => {
  // const tab = TYPE_GROUPS.find((v) => v.key === tabKey);
  let newValues = [];
  // 删除
  if (!checked) {
    newValues = values.filter((i) => i.id != checkedValues.id);
    return newValues;
  }

  newValues = values;
  //增加没有的
  checkedValues.forEach((checkedValue) => {
    if (!includeValue(values, checkedValue)) {
      newValues.push(checkedValue);
    }
  });
  return newValues;
};

export const CURRENT_PROCESSOR = {
  name: '当前节点处理人',
  type: 'CURRENT_PROCESSOR',
  id: 'CURRENT_PROCESSOR',
};

export const EXTERNAL_LIST = [
  {
    type: 'FRANCHISEE',
    name: '加盟商',
    id: 'F0',
  },
  {
    type: 'CONTRACTOR',
    name: '施工方',
    id: 'C0',
  },
  {
    type: 'SUPPLIER',
    name: '供应商',
    id: 'S0',
  },
];
export const PARTNERSTOREMANAGER_LIST = [
  {
    type: 'ROLE',
    optionType: 'ShopManager',
    name: '店长',
    id: '2212',
  },
  {
    type: 'ROLE',
    name: '师傅',
    optionType: 'Expert',
    id: '2214',
  },
];

export const staffColumns = [
  {
    title: '姓名',
    dataIndex: 'staffName',
  },
  {
    title: '部门名称',
    dataIndex: 'deptName',
  },
  {
    title: '职位',
    dataIndex: 'postName',
  },
];
export const franchiseeColumns = [
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: '手机号',
    dataIndex: 'phone',
  },
  {
    title: '加盟时间',
    dataIndex: 'joinDate',
  },
];
