<template>
  <div class="dept-tree-select-wrapper">
    <a-input-search
      v-model.trim="searchKey"
      @change="onChangeSearch"
      placeholder="请输入搜索词"
      allow-clear
      enter-button
      style="width: 100%"
    ></a-input-search>
    <div class="tree-wrapper">
      <a-tree
        :replace-fields="{ children: 'subNodes', title: 'name', key: 'id' }"
        :tree-data="showTreeData"
        show-line
        v-model:checkedKeys="val"
        :expandedKeys="expandedKeys"
        @check="onCheck"
        :auto-expand-parent="true"
        @select="onSelect"
        @expand="onExpand"
        :checkStrictly="true"
        :checkable="true"
      >
        <a-icon slot="switcherIcon" type="down" />
        <template slot="title" slot-scope="{ dataRef }">
          <span v-if="dataRef.name.indexOf(searchKey) > -1">
            {{ dataRef.name.substr(0, dataRef.name.indexOf(searchKey)) }}
            <span style="color: #f50">{{ searchKey }}</span>
            {{ dataRef.name.substr(dataRef.name.indexOf(searchKey) + searchKey.length) }}
          </span>
          <span v-else>{{ dataRef.name }}</span>
        </template>
      </a-tree>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: {
    value: { default: [] },
    treeData: {
      type: Array,
      default() {
        return [];
      },
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      searchKey: '',
      expandedKeys: [],
      showTreeData: [],
      dataList: [],
    };
  },
  watch: {
    treeData: {
      handler: function (val) {
        this.expandedKeys = [val?.[0]?.id ?? ''];
        this.showTreeData = this.treeData;
      },
      deep: true,
      immediate: true,
    },
    // searchKey(val) {
    //   if (val === '') {
    //     this.autoExpandParent = true;
    //     this.expandedKeys = [this.treeData?.[0]?.id ?? ''];
    //     return;
    //   }
    //   const keys = [];
    //   const filterSearchKey = (node) => {
    //     if (!node) {
    //       return;
    //     }
    //     if (node.name.indexOf(val) > -1) {
    //       keys.push(node.id);
    //     }
    //     node.subNodes?.forEach((node) => filterSearchKey(node));
    //   };
    //   this.treeData?.forEach((item) => filterSearchKey(item));
    //   this.expandedKeys = keys;
    //   this.autoExpandParent = true;
    // },
  },
  methods: {
    onChangeSearch: _.debounce(
      function (val) {
        this.dataList = [];
        let treeDataTemp = JSON.parse(JSON.stringify(this.treeData)); //树结构的数据
        let searchTree = [];
        if (this.searchKey) {
          let treeArr = [];
          this.generateList(this.treeData);
          this.dataList.forEach((item) => {
            if (item.name.indexOf(this.searchKey) > -1) {
              let parentList = this.findParent(item.id, treeDataTemp, 'parentId');
              treeArr = treeArr.concat(parentList); //合并所有满足条件数组
              const obj = {}; //数组去重
              treeArr = treeArr.reduce((total, next) => {
                obj[next.id] ? '' : (obj[next.id] = true && total.push(next));
                return total;
              }, []);
              searchTree = this.translateArrToTree(treeArr);
            }
            return null;
          });
          this.expandedKeys = treeArr.map((i) => i.id);
          this.showTreeData = searchTree;
          this.autoExpandParent = true;
        } else {
          this.autoExpandParent = true;
          this.showTreeData = JSON.parse(JSON.stringify(this.treeData));
          this.expandedKeys = [this.showTreeData?.[0]?.id ?? ''];
        }
      },
      220,
      { leading: false, trailing: true },
    ),

    //将树结构转为普通数组
    generateList(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        this.dataList.push({ ...node });
        if (node.subNodes) {
          this.generateList(node.subNodes);
        }
      }
    },

    //找到子节点的所有相关父元素
    findParent(id, list = [], pidName, result = []) {
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i]; // 找到目标
        if (item.id === id) {
          // 加入到结果中
          result.push({ id: item.id, name: item.name, parentId: item[pidName], type: item.type }); // 因为可能在第一层就找到了结果，直接返回当前结果
          if (result.length === 1) return result;
          return true;
        } // 如果存在下级节点，则继续遍历
        if (item.subNodes) {
          // 预设本次是需要的节点并加入到最终结果result中
          result.push({ id: item.id, name: item.name, parentId: item[pidName], type: item.type });
          const find = this.findParent(id, item.subNodes, pidName, result); // 如果不是false则表示找到了，直接return，结束递归
          if (find) {
            return result;
          } // 到这里，意味着本次并不是需要的节点，则在result中移除
          result.pop();
        }
      } // 如果都走到这儿了，也就是本轮遍历children没找到，将此次标记为false
      return false;
    },
    translateArrToTree(arr) {
      //将节点数组转为树结构
      // 1.先是制作字典能够获得每一行的信息
      const newArr = [];
      const map = [];
      arr.forEach((item) => {
        // 为了方便给每一项都添加上children
        // 对象是直接.就可以添加属性的
        item.subNodes = [];
        const key = item.id;
        map[key] = item;
      });
      // 2.遍历每一项,然后有父级的添加到父级的children中,没有父级的直接添加到新的数组中
      arr.forEach((item) => {
        const parent = map[item.parentId];
        if (parent) {
          parent.subNodes.push(item);
        } else {
          newArr.push(item);
        }
      });
      return newArr;
    },
    onExpand(expandedKeys, { expanded: bool, node }) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(value, node) {
      let data = null;
      if (node.selected) {
        data = node.selectedNodes[0].data?.props?.dataRef;
      }
      this.$emit('select', data);
    },
    onCheck(checkedKeys, { checked, checkedNodes, node, event }) {
      let curNode = node?.dataRef ?? {};
      const checkedValues = checked
        ? checkedNodes.map((item) => item.data?.props?.dataRef ?? [])
        : curNode;

      // 如果是删除的话,就传当前删除的这一条数据
      this.$emit('check', { checkedValues, checked });
    },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val.checked);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.dept-tree-select-wrapper {
  max-height: 320px;
  overflow: auto;
  .tree-wrapper {
    height: 280px;
    overflow: overlay;
  }
}
</style>
