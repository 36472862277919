<template>
  <div class="people-visible-range-wrapper">
    <a-modal
      :visible="visible"
      title="人员选择"
      :destroyOnClose="true"
      @ok="onOk"
      @cancel="onCancel"
      :dialog-style="{ top: '30px' }"
      :bodyStyle="{ padding: '12px' }"
      width="80%"
    >
      <div class="people-visible-range-container">
        <div class="show-container">
          <checked-list
            :value="checkedList"
            @change="checkedList = $event"
            @delete="onDelete"
            @deleteAll="onDeleteAll"
          ></checked-list>
        </div>
        <div class="select-container">
          <div class="left">
            <check-tabs
              ref="checkTabs"
              :checkedList="checkedList"
              :noFormSearch="noFormSearch"
              :hasDynamic="hasDynamic"
              :hasExternal="hasExternal"
              :isSubProgress="isSubProgress"
              @select="onCheckTabsSelected"
              @check="onTabsCheck"
              @activeKey="handleTabChange"
            ></check-tabs>
          </div>
          <div class="right">
            <a-spin :delay="200" :spinning="deptTreeSelectSpinning">
              <right-detail-list
                :data-source="dataSource"
                :value="rightCheckedKeys"
                :columns="columns"
                @search="onDetailSearch"
                :pagination="pagination"
                @check="onStaffCheck($event, true)"
                @unCheck="onStaffCheck($event, false)"
              ></right-detail-list>
            </a-spin>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import CheckedList from './CheckedList.vue';
import { getExternalFranchiseeList, getStaffList } from '@/api/patrolTask/store.js';
import CheckTabs from './CheckTabs';
import _ from 'lodash';
import RightDetailList from './RightDetailList.vue';
import { staffColumns, franchiseeColumns, checkValues } from './peopleconstant';

export default {
  components: { CheckedList, CheckTabs, RightDetailList },
  props: {
    value: {
      default() {
        return [];
      },
    },
    visible: {
      type: Boolean,
      default: () => false,
    },
    noFormSearch: {
      type: Boolean,
      default: true,
    },
    // 是否有动态
    hasDynamic: {
      type: Boolean,
      default: () => true,
    },
    // 是否有外部人员
    hasExternal: {
      type: Boolean,
      default: () => true,
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    //是否是子流程调用此组件
    isSubProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataSource: [],
      columns: [],
      dataSourceTotal: 0,
      current: 1,
      checkTabsActiveKey: 'dynamic',
      // 增加保存内容
      checkedList: [],
      selectedItem: null,
      deptTreeSelectSpinning: false,
      suggest: '',
    };
  },
  watch: {
    visible(isShow) {
      if (isShow) {
        this.columns = [];
        this.dataSource = [];
        this.checkedList = _.cloneDeep(this.val);
      }
    },
  },
  methods: {
    onDetailSearch(suggest) {
      // 记录，确保分页查询时候依然保持搜索条件
      this.suggest = suggest;
      if (this.checkTabsActiveKey === 'dept') {
        this.queryStaffData();
      } else if (this.checkTabsActiveKey === 'external') {
        this.queryFranchiseeList();
      }
    },
    queryStaffDataByLinkage(activeKey) {
      this.checkTabsActiveKey = activeKey;
      // 清空人员列表
      this.dataSource = [];
      this.dataSourceTotal = 0;
    },
    onDeleteAll() {
      this.checkedList = [];
    },
    onDelete(item) {
      this.checkedList = this.checkedList.filter(
        (row) => !(item.type === row.type && item.id === row.id),
      );
    },
    onStaffCheck(rows, selected) {
      const ids = rows.map((item) => item.id);
      if (selected) {
        this.checkedList.push(...rows);
      } else {
        this.checkedList = this.checkedList.filter((item) => {
          const notStaffOrExternal = !['STAFF', 'FRANCHISEE', 'CONTRACTOR', 'SUPPLIER'].includes(
            item.type,
          );
          const isStaff = item.type === 'STAFF' && !ids.includes(item.id);
          const isExternal =
            ['FRANCHISEE', 'CONTRACTOR', 'SUPPLIER'].includes(item.type) && !ids.includes(item.id);
          return notStaffOrExternal || isStaff || isExternal;
        });
      }
    },
    onTabsCheck({ checkedValues, checked }) {
      checkedValues = checkedValues.map((item) => ({
        ...item,
        optionType: item.id == 2212 ? 'ShopManager' : item.id == 2214 ? 'Expert' : undefined,
      }));
      this.checkedList = checkValues(
        this.checkedList,
        checkedValues,
        checked,
        this.checkTabsActiveKey,
      );
    },
    handleTabChange(activeKey) {
      // 因为本期不能按职位搜索，所以保存当前key，在搜索接口做一层拦截
      this.checkTabsActiveKey = activeKey;
      this.selectedItem = null;
      this.loadDetailData();
    },
    loadDetailData() {
      //如果左侧选中了内容，优先按选中内容加载明细数据
      if (this.checkTabsActiveKey === 'dept') {
        this.queryStaffData();
      } else if (this.checkTabsActiveKey === 'external') {
        //外部人员
        this.queryFranchiseeList();
      } else {
        this.columns = [];
        this.dataSource = [];
        this.dataSourceTotal = 0;
      }
    },
    queryStaffData(queryData = {}) {
      // 因为暂时不能够按照职位去搜索，所以通过key做拦截
      if (this.checkTabsActiveKey !== 'dept') {
        this.$message.warning('暂时不能按照该维度搜索人员');
        return;
      }

      this.columns = staffColumns;
      this.deptTreeSelectSpinning = true;
      const { pageNo = 1, pageSize = 20 } = queryData;
      this.current = pageNo;
      const params = {
        pageNo,
        pageSize,
        data: {
          authorized: true,
        },
      };
      this.suggest && (params.data.keyword = this.suggest);
      if (this.selectedItem) {
        if (this.selectedItem.type === 'DEPT') {
          params.data.deptId = this.selectedItem.id;
        } else if (this.selectedItem.type === 'POSITION') {
          //postId && (params.data.postId = postId);
          params.data.postId = postId;
        }
      }

      getStaffList(params).then((res) => {
        if (!res.data.succ) {
          this.$message.error(res.data.error || '查询员工失败');
          return;
        }
        // 数据转化
        this.dataSource = (res?.data?.result?.records ?? []).map((item) => ({
          ...item,
          id: item.staffId,
          name: item.staffName,
          type: 'STAFF',
        }));
        this.dataSourceTotal = res?.data?.result?.total ?? 0;
        this.deptTreeSelectSpinning = false;
      });
    },
    queryFranchiseeList(queryData = {}) {
      // 非外部人员 tab
      if (this.checkTabsActiveKey !== 'external') {
        return;
      }

      this.deptTreeSelectSpinning = true;
      const { pageNo = 1, pageSize = 20 } = queryData;
      this.current = pageNo;
      const params = {
        pageNo,
        pageSize,
        data: {
          authorized: true,
          type: this.selectedItem?.type ?? '',
        },
      };
      //加盟商
      this.suggest && (params.data.name = this.suggest);
      this.columns = franchiseeColumns;

      getExternalFranchiseeList(params).then((res) => {
        if (!res.data.succ) {
          this.$message.error(res.data.error || '查询加盟商失败');
          return;
        }

        // 数据转化
        this.dataSource = (res?.data?.result?.records ?? []).map((item) => ({
          ...item,
          id: '' + item.id, //转成字符串类型给后端
          name: item.name,
          // type: 'FRANCHISEE',
        }));
        this.dataSourceTotal = res?.data?.result?.total ?? 0;
        this.deptTreeSelectSpinning = false;
      });
    },
    onOk() {
      this.val = this.checkedList.map((v) => ({
        type: v.type,
        name: v.name,
        id: v.id,
        optionType: v.optionType??'',
      }));
      this.$emit('update:visible', false);
    },
    onCancel() {
      this.$emit('update:visible', false);
    },
    // 左边的树被点击
    onCheckTabsSelected(e) {
      this.selectedItem = e;
      this.loadDetailData();
    },
  },
  computed: {
    rightCheckedKeys() {
      if (
        (this.selectedItem && this.selectedItem.type === 'DEPT') ||
        this.checkTabsActiveKey === 'dept'
      ) {
        return this.checkedList.filter((v) => v.type === 'STAFF').map((v) => v.id);
      } else if (this.checkTabsActiveKey === 'external') {
        return this.checkedList
          .filter((v) => ['FRANCHISEE', 'CONTRACTOR', 'SUPPLIER'].includes(v.type))
          .map((v) => v.id);
      }
      return [];
    },
    pagination() {
      return {
        total: this.dataSourceTotal,
        showTotal: (total, range) => `共 ${total} 条记录`,
        change: (e) => {
          if (this.checkTabsActiveKey === 'dept') {
            this.queryStaffData(e);
          } else if (this.checkTabsActiveKey === 'external') {
            //外部人员
            this.queryFranchiseeList(e);
          }
        },
        showSizeChanger: true,
        defaultPageSize: 10,
        current: this.current,
      };
    },
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.people-visible-range-container {
  display: flex;
  flex-direction: column;
}
.people-check {
  color: #5087ec;
  cursor: pointer;
}
.show-container {
}
.select-container {
  display: flex;
  margin-top: 22px;
  .left,
  .right {
    border: 1px solid #d9d9d9;
    flex: 1 1 140px;
    &.right {
      flex: 1 1 320px;
      margin-left: 12px;
    }
  }
}
</style>
