import { queryShop } from '@/api/workflow';
import useMessage from '@/hooks/useMessage';
const { confirm } = useMessage();
export default function useInitDataDispose() {
  const getFoundShopFoundExpertUserPicker = (formsTemp, isEdit = false) => {
    return new Promise(async (resolve, reject) => {
      let shopManagers;
      let experts;
      let shopManageItem, expertItem;

      for (let item of formsTemp) {
        if (item.name === 'UserPicker') {
          if (item.props?.fieldTag == 'ShopManager') {
            shopManageItem = item;
          }
          if (item.props?.fieldTag == 'Expert') {
            expertItem = item;
          }
          if (shopManageItem && expertItem) {
            try {
              const data = (await queryShop()).data;
              shopManagers = data.shopManagers;
              experts = data.experts;
              break;
            } catch (error) {
              resolve(formsTemp);
              break;
            }
          }
        }
      }
      if (shopManageItem) {
        shopManageItem.props.userPicker = shopManagers;
        if (isEdit && shopManageItem?.value?.id) {
          shopManageItem.props.userPicker.push(shopManageItem.value);
        }
      }
      if (expertItem) {
        expertItem.props.userPicker = experts;
      }
      resolve(formsTemp);
    });
  };
  const shopManagerValueChangeSetExpertValue = async (items, formRef) => {
    if (!items || !formRef) return;
    let foundExpertItem, foundShopManagerItem;
    for (let item of items) {
      if (item.oldType == 'UserPicker' && item.type == 'select') {
        if (!foundShopManagerItem && item?.fieldTag == 'ShopManager') {
          foundShopManagerItem = item;
        }
        if (!foundExpertItem && item?.fieldTag == 'Expert') {
          foundExpertItem = item;
        }
        if (foundShopManagerItem && foundExpertItem) {
          break;
        }
      }
    }
    if (foundShopManagerItem && foundExpertItem) {
      if (foundExpertItem?.rules) {
        foundExpertItem.rules[0].required = false;
      }
      foundShopManagerItem.onChange = (v) => {
        const selectShopManagerUser = foundShopManagerItem.userPicker.find((i) => i.id === v);
        if (selectShopManagerUser) {
          const currentMasterId = formRef.getFieldValue(foundExpertItem.dataFIndex);
          if (currentMasterId !== selectShopManagerUser.masterId) {
            setTimeout(() => {
              formRef.setFieldsValue({
                [foundExpertItem.dataIndex]: selectShopManagerUser.masterId,
              });
              confirm('店长选择后则不支持变更，请谨慎选择', '提示', { showCancelButton: false });
            }, 100);
          }
        }
      };
      console.log('foundShopManagerItem', foundShopManagerItem);
      console.log('foundExpertItem', foundExpertItem);
    }
  };
  return {
    shopManagerValueChangeSetExpertValue,
    getFoundShopFoundExpertUserPicker,
  };
}
