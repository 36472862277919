<template>
  <div class="check-tab-select-wrapper">
    <a-tabs
      :value="activeKey"
      style="max-width: 430px"
      @change="
        activeKey = $event;
        $emit('activeKey', $event);
      "
    >
      <a-tab-pane key="dynamic" tab="动态" v-if="!noFormSearch && hasDynamic">
        <mz-data-wrapper :hasData="formTreeData.length > 0" :spinning="formSpinning" :delay="200">
          <left-tree-select
            :tree-data="filterData(formTreeData)"
            :value="formIds"
            @select="$emit('select', $event)"
            @check="$emit('check', $event)"
          ></left-tree-select>
        </mz-data-wrapper>
      </a-tab-pane>
      <a-tab-pane key="role" tab="角色">
        <mz-data-wrapper :hasData="roleTreeData.length > 0" :spinning="roleSpinning" :delay="200">
          <left-tree-select
            :tree-data="roleTreeData"
            :value="roleIds"
            @select="$emit('select', $event)"
            @check="$emit('check', $event)"
          ></left-tree-select>
        </mz-data-wrapper>
      </a-tab-pane>
      <a-tab-pane key="external" tab="外部人员" v-if="hasExternal">
        <mz-data-wrapper
          :hasData="externalTreeData.length > 0"
          :spinning="externalSpinning"
          :delay="200"
        >
          <left-tree-select
            :tree-data="externalTreeData"
            :value="externalIds"
            @select="$emit('select', $event)"
            @check="$emit('check', $event)"
          ></left-tree-select>
        </mz-data-wrapper>
      </a-tab-pane>
      <a-tab-pane key="dept" tab="内部人员">
        <a-spin :delay="200" :spinning="peopleTreeSelectSpinning">
          <left-tree-select
            :tree-data="deptTreeData"
            :value="deptIds"
            @select="$emit('select', $event)"
            @check="$emit('check', $event)"
          ></left-tree-select>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="partnerStoreManager" tab="合伙制店长">
        <mz-data-wrapper
          :hasData="partnerStoreManagerData.length > 0"
          :spinning="roleSpinning"
          :delay="200"
        >
          <left-tree-select
            :tree-data="partnerStoreManagerData"
            :value="partnerStoreManagerIds"
            @select="$emit('select', $event)"
            @check="$emit('check', $event)"
          ></left-tree-select>
        </mz-data-wrapper>
      </a-tab-pane>
      <!-- <a-tab-pane :key="1" tab="职位">
        <a-spin :delay="200" :spinning="postSpinning">
          <position-tree-select
            :tree-data="positionTreeData"
            :value="postIds"
            @select="$emit('select', { type: 'POST', data: $event })"
            @check="$emit('check', { type: 'POST', data: $event })"
          ></position-tree-select>
        </a-spin>
      </a-tab-pane> -->
      <!--  <a-tab-pane :key="1" tab="角色"></a-tab-pane> -->
      <!-- <a-tab>已存数据</a-tab> -->
    </a-tabs>
  </div>
</template>
<script>
import LeftTreeSelect from './LeftTreeSelect.vue';
import { getDeptAll } from '@/api/autho/userManage.js';
import { getMaterialList } from '@/api/workflow';
import { getRolePageList } from '@/api/autho/role';
import getSnapshotParams from '@/pages/workflow/template/hooks/getSnapshotParams.js';
import {
  CURRENT_PROCESSOR,
  EXTERNAL_LIST,
  PARTNERSTOREMANAGER_LIST,
  reduceValues,
} from './peopleconstant';

export default {
  components: { LeftTreeSelect },
  props: {
    value: { default: () => [] },
    checkedList: { type: Array, default: () => [] },
    noFormSearch: { type: Boolean, default: true },
    hasDynamic: { type: Boolean, default: true }, // 是否有动态
    hasExternal: { type: Boolean, default: true }, // 是否有外部人员
    isSubProgress: { type: Boolean, default: false }, //是否是子流程调用此组件
  },
  data() {
    return {
      activeKey: 'dynamic',
      deptTreeData: [],
      positionTreeData: [],
      roleTreeData: [],
      partnerStoreManagerData: [],
      externalTreeData: [],
      formTreeData: [],
      peopleTreeSelectSpinning: false,
      postSpinning: false,
      formSpinning: false,
      roleSpinning: false,
      externalSpinning: false,
    };
  },
  methods: {
    filterData(tree) {
      return tree.filter(item => {
        return item.id !== 'CURRENT_PROCESSOR';
      });
    },
    // 获取可用表单组件树 --动态
    queryFormTreeData() {
      if (this.noFormSearch || !this.hasDynamic) {
        return;
      }
      this.formSpinning = true;
      const params = {
        pageNo: 1,
        pageSize: -1,
        itemType: 'userPicker',
      };
      if (this.isSubProgress) {
        params.mainTemplateId = this.$store.state.workflow.mainTemplateInfo.templateId;
      } else {
        params.currentNodeId = this.$store.state.workflow.selectedNode.bizId;
      }
      return getMaterialList({ ...params, ...getSnapshotParams() })
        .then((res) => {
          if (res.code !== 0) {
            this.$message.error(res.message || '获取表单失败');
            return;
          }

          // 过滤禁用的字段
          let tableData = res?.data?.records ?? [];
          tableData = tableData.filter((i) => {
            const props = JSON.parse(i?.props);
            return !props?.disabledField;
          });
          // 数据转换
          this.formTreeData =
            tableData.map((item) => ({
              ...item,
              name: item.itemTitle,
              type: 'FIELD',
              id: '' + item.itemId,
            })) ?? [];

          this.formTreeData.unshift(CURRENT_PROCESSOR);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.formSpinning = false;
        });
    },
    // 获取部门数据
    queryTreeData(suggest = '') {
      this.peopleTreeSelectSpinning = true;
      const params = {
        suggest,
        tenant: this.$store.state.tenant.tenant.tenantId,
      };
      return getDeptAll(params)
        .then((res) => {
          if (!res.data.succ) {
            this.$message.error(res.data.error || '获取部门失败');
            return;
          }
          // 做一个简单的数据转换
          this.deptTreeData = [res?.data?.result];
          // 递归将this.deptTreeData中的deptId转化成id
          const recursion = (data) => {
            data.forEach((item) => {
              item.id = item.deptId;
              if (item.subNodes && item.subNodes.length) {
                recursion(item.subNodes);
              }
            });
          };
          recursion(this.deptTreeData);
        })
        .finally(() => {
          this.peopleTreeSelectSpinning = false;
        });
    },
    // queryPositionData() {
    //   this.postSpinning = true;
    //   return getOrgPostList({ suggest: '' })
    //     .then((res) => {
    //       if (res.data.code !== 0) {
    //         this.$message.error(res.data.msg || '获取职位失败');
    //         return;
    //       }
    //       // 数据转换
    //       this.positionTreeData =
    //         res.data?.data?.map((item) => ({
    //           ...item,
    //           type: 'POST',
    //           id: item.postId,
    //         })) ?? [];
    //     })
    //     .finally(() => {
    //       this.postSpinning = false;
    //     });
    // },
    queryRoleData() {
      this.roleSpinning = true;
      return getRolePageList({ data: { name: '', status: 1 }, pageNo: 1, pageSize: 9999 })
        .then((res) => {
          if (res.data.succ !== 1) {
            this.$message.error(res.data.msg || '获取角色失败');
            return;
          }
          // 数据转换
          this.roleTreeData =
            res.data?.result?.records?.map((item) => ({
              ...item,
              type: 'ROLE',
              id: '' + item.id,
            })) ?? [];
        })
        .finally(() => {
          this.roleSpinning = false;
        });
    },
    queryPartnerStoreManagerData() {
      this.partnerStoreManagerData = PARTNERSTOREMANAGER_LIST;
    },
    queryExternalData() {
      this.externalSpinning = true;
      this.externalTreeData = EXTERNAL_LIST;
      this.externalSpinning = false;
    },
  },
  async created() {
    this.queryFormTreeData();
    this.queryRoleData();
    this.queryPartnerStoreManagerData();
    this.queryExternalData();
    this.queryTreeData().then(() => this.$emit('activeKey', this.activeKey));
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val.checked);
      },
    },
    formIds() {
      return reduceValues(this.checkedList, 'dynamic');
    },
    roleIds() {
      return reduceValues(this.checkedList, 'role');
    },
    partnerStoreManagerIds() {
      return reduceValues(this.checkedList, 'partnerStoreManager');
    },
    externalIds() {
      return reduceValues(this.checkedList, 'external');
    },
    deptIds() {
      return reduceValues(this.checkedList, 'dept');
    },
  },
};
</script>
<style lang="scss" scoped>
.check-tab-select-wrapper {
  min-height: 320px;
  overflow: auto;
  //border: 1px solid #d9d9d9;
  padding: 12px;
  border-radius: 4px;
}
</style>
