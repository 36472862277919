<template>
  <div class="img-upload">
    <div :class="{ 'content-wrapper': true }">
      <a-upload
        :disabled="isReadonly || disabled"
        :accept="accept"
        :list-type="getListType"
        :multiple="getMultiple"
        :show-upload-list="getShowUploadList"
        :beforeUpload="beforeUpload"
        :customRequest="customRequest"
      >
        <div class="img-list" v-if="isPictureCard" @click="handleUploadClick">
          <div
            v-if="(isPicture || isVideo) && !isUploadButtonDisabled"
            :style="'width:' + width + ';height:' + height + ';margin-bottom:5px'"
            :class="['upload-btn']"
            v-show="!isReadonly"
          >
            <a-icon style="font-size: 20px" :type="isShowSpinning ? 'loading' : ''" />
            <img
              alt=""
              style="width: 20px; height: 20px; margin: 0 auto"
              :src="config.btnUrl"
              v-if="config.btnUrl && !isShowSpinning"
            />
            <!-- <div class="ant-upload-text">{{ isPicture ? '上传图片' : '上传视频' }}</div> -->
            <div>{{ fileList.length }} / {{ getMaxCount }}</div>
          </div>
          <div
            class="img-container"
            :style="
              'width:' +
              width +
              ';height:' +
              height +
              ';padding:' +
              spacePadding +
              ';margin-bottom:5px'
            "
            :key="index"
            v-for="(item, index) in fileList"
          >
            <div v-if="isVideo" class="video-div">
              <img
                class="img"
                :src="`${item.url}?x-oss-process=video/snapshot,t_100,w_0,h_0,m_fast`"
                style="object-fit: cover"
                alt="video"
              />
            </div>
            <img class="img" :src="item.url" style="object-fit: cover" alt="img" v-else />
            <span class="tip" v-if="tip">{{ tip }}</span>
            <a-space class="options" @click.stop="() => {}">
              <a-icon type="eye" @click.stop="showPreview(item.url)" />
              <a-icon
                type="delete"
                @click.stop="handleDelete(index)"
                v-if="!disabled && !isReadonly"
              />
            </a-space>
          </div>
        </div>
        <div class="file-list-custom" v-else @click="handleUploadClick">
          <div
            :style="'width:' + width + ';height:' + height"
            :class="['upload-btn-file', isUploadButtonDisabled ? 'disabled' : 'normal']"
            v-show="!isReadonly"
          >
            <div class="content">
              <div class="link">
                <img
                  src="https://file.ynzyq.net/frontend/static/cb99b43480d8ec0e6fe16a2788d75fdc_48_48.png"
                  alt=""
                />
                上传文件
              </div>
            </div>
          </div>
          <template v-if="isReadonly">
            <div class="file-block-list" @click.stop>
              <div
                class="file-block-item"
                style="margin-bottom: 5px"
                v-for="(file, index) in fileList"
                :key="index"
              >
                <div class="file-type-icon">
                  <img :src="getIconByFileType(file.format)" alt="1" />
                </div>
                <div class="file-content">
                  <a-tooltip class="file-top" :title="file.name">{{ file.name || ' ' }}</a-tooltip>
                  <div class="file-bottom">{{ getSize(file.size) }}MB</div>
                </div>
                <div class="file-download">
                  <a-icon
                    type="eye"
                    :style="{ fontSize: '16px', color: '#6197FF' }"
                    @click.stop="filePreview(file, index)"
                  />
                  <img
                    @click.stop="fileDownload(file)"
                    src="https://file.ynzyq.net/frontend/static/4c56c22c4101bc1d8bcf860f712a4483_32_32.png"
                    alt="123"
                  />
                </div>
              </div>
            </div>
          </template>
          <div v-else class="file-list-wrapper">
            <div
              class="file-list-item-wrapper"
              v-for="(file, index) in fileList"
              :key="index"
              @click.stop="filePreview(file, index)"
            >
              <img :src="getIconByFileType(file.format)" alt="1" class="file-icon" />
              <div class="file-names">{{ file.name }}</div>
              <a-icon
                type="close"
                class="remove-icon"
                v-show="!isReadonly"
                @click.stop="deleteFile(index)"
              />
            </div>
          </div>
        </div>
      </a-upload>
    </div>

    <!-- <a-modal title="" :visible="previewVisible" @cancel="handleCancel" :footer="null">
      <span style="display: inline-block">
        <img style="width: 472px" :src="previewUrl" alt="" />
      </span>
    </a-modal> -->
    <canvas id="canvas" style="display: none"></canvas>

    <showImgAndVideoMask
      v-if="previewVisible"
      filePreviewShow
      :currentIndex="previewIndex"
      :defaultScaleSize="1"
      :imgList="previewUrl"
      @close="previewVisible = false"
    ></showImgAndVideoMask>
  </div>
</template>
<script>
// 调后端接口上传文件
import { uploadFile } from '@/api/workflow';
import showImgAndVideoMask from '@/pages/workflow/components/showImgAndVideoMask';
import _ from 'lodash';
import { IMAGE_UPLOAD, VIDEO_UPLOAD, FILE_UPLOAD } from '@/components-antd/MzFastForm/constants';
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  components: {
    showImgAndVideoMask,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tip: {
      type: String,
      default: '',
    },
    //展示提示文字
    showTip: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    // 图片展示填充
    spacePadding: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Array],
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    showUploadList: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: 'picture-card',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxNum: {
      //只有 multiple 为true有效
      type: Number,
      default: Infinity,
    },
    // 限制尺寸(宽高比)
    limitDimensionsInfo: {
      type: Object,
      default: null,
    },
    enableZip: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
    },
    customCheckFileType: Function,
  },
  data() {
    return {
      ossFilePath: '',
      // 文件对象列表
      fileList: [],
      isShowSpinning: false,
      previewVisible: false,
      previewUrl: '',
      customSpinning: false,
      currentBackgroundIndex: 0,
      previewIndex: 0,
    };
  },

  watch: {
    value: {
      handler(data) {
        this.fileList = data ? data : [];
      },
      immediate: true,
    },
  },
  methods: {
    getSize(size) {
      return typeof size === 'number' ? (size / 1000).toFixed(2) : '';
    },
    filePreview(file, index) {
      // 图片和视频当前页面预览
      if (this.isImgOrVideo(file.format)) {
        this.showPreview(file.url);
        return;
      }
      window.open(file.url, '_blank');
    },
    isImgOrVideo(type) {
      if (!type) return false;
      const fileType = type.split('.').pop();
      const imgList = ['png', 'jpg', 'jpeg', 'gif'];
      const videoList = ['mp4', 'm4v', 'avi', 'm3u8', 'webm', 'mov', '3gp'];
      const allList = [...imgList, ...videoList];
      return allList.includes(fileType.toLowerCase());
    },
    fileDownload(file) {
      // downloadFile({ url: col.url, fileName: col.fileName }).catch((e) => {
      //   message.error(e.message || '下载失败');
      // });
      const a = document.createElement('a');
      const defaultUrl =
        process.env.NODE_ENV === 'development' ? 'https://ykdtest01.ynzyq.net' : '';
      a.href = `${defaultUrl}/api/flow/common/download?url=${file.url}&fileName=${file.name}`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    deleteFile(index) {
      this.$confirm('确认删除该文件吗？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          this.fileList.splice(index, 1);
        })
        .catch(() => {});
    },
    // 根据文件类型，返回icon地址
    getIconByFileType(type) {
      let fileType = type.toLowerCase();
      let icon = '';
      switch (fileType) {
        case '.png':
        case '.jpeg':
        case '.jpg':
        case '.gif':
          icon =
            'https://file.ynzyq.net/frontend/static/d136d026bd3c67542e5a7e4e52047471_32_32.png';
          break;
        case '.mov':
        case '.mp4':
        case '.m4v':
        case '.avi':
        case 'webm':
        case 'm3u8':
        case '3gp':
          icon =
            'https://file.ynzyq.net/frontend/static/5ac72acd28c10400d9382f504dc5b4e0_48_48.png';
          break;
        // case 'application/pdf':
        //   icon = 'icon-pdf';
        //   break;
        case '.docx':
        case '.doc':
          icon =
            'https://file.ynzyq.net/frontend/static/257146645cd5d4f217f195e6dc0e946d_32_32.png';
          break;
        case '.xlsx':
        case '.xls':
          icon =
            'https://file.ynzyq.net/frontend/static/7eba3969564c5c7cc11fe83716967307_32_32.png';
          break;
        case '.ppt':
        case '.pptx':
          icon =
            'https://file.ynzyq.net/frontend/static/9f819680e37b5eb181653f22b61d2696_110_124.png';
          break;
        case '.pdf':
          icon =
            'https://file.ynzyq.net/frontend/static/aca2095a88a5201b6f1fb2bb706ef606_110_124.png';
          break;
        // case '.vnd.ms-powerpoint':
        // case '.vnd.openxmlformats-officedocument.presentationml.presentation':
        //   icon = 'icon-ppt';
        //   break;
        case '.zip':
        case '.rar':
        case '.x-zip-compressed':
          icon =
            'https://file.ynzyq.net/frontend/static/fb38cd30d713e54cf227dd211e2f5872_48_48.png';
          break;
        // case 'text/plain':
        //   icon = 'icon-txt';
        //   break;
        default:
          icon =
            'https://file.ynzyq.net/frontend/static/dff97b0818af5e81b0df4f1030d42b4b_128_128.png';
          break;
      }
      return icon;
    },
    handleUploadClick(event) {
      // 如果按钮不可选择，取消冒泡
      if (this.isUploadButtonDisabled) {
        // 弹出警告提示：当前已达到最大上传数量2条。
        event.stopPropagation();
        return;
      }
    },
    // 通过服务器上传到oss
    uploadToServer(file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', file.type);

      return uploadFile(formData)
        .then((res) => {
          if (res.code === 0) {
            const MAX_FILE_COUNT = this.getMaxCount - 1;
            if (this.fileList.length > MAX_FILE_COUNT) {
              this.$message.error(`只能上传 ${this.getMaxCount} 个文件`);
              return;
            }
            const file = res.data;
            if (!this.getMultiple) {
              this.fileList = [file];
              this.$emit('change', this.fileList);
            } else {
              this.fileList.push(file);
              this.$emit('change', this.fileList);
            }
          } else {
            this.$message({
              message: '上传失败',
              type: 'error',
            });
            file.status = 'error';
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isShowSpinning = false;
          file.status = 'done';
        });
    },
    // 图片类型校验
    checkImageType(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.warning('只能上传jpg/png格式图片!');
        return false;
      }
      return true;
    },
    // 视频类型校验
    checkVideoType(file) {
      // video/quicktime 是 mov类型的视频
      if (!['video/mp4', 'video/webm', 'video/mov', 'video/quicktime'].includes(file.type)) {
        this.$message.warning('只能上传mp4/mov/webm格式视频!');
        return false;
      }
      return true;
    },
    // 上传文件的校验
    checkFileType(file) {
      // const isFileType = file.type === this.config.fileType;
      // if (!isFileType) {
      //   this.$message.warning(`只能上传${this.config.fileType}格式文件!`);
      //   return false;
      // }
      return true;
    },
    // 文件类型校验函数
    checkUploadType(file) {
      const maxSize = this.config?.maxSize ?? 20;
      // 检测质量
      const isLt2M = file.size / 1024 / 1024 < maxSize;
      if (!isLt2M) {
        this.$message.error(`文件大小不能超过${maxSize}MB`);
        return false;
      }

      // 自定义方法
      if (typeof this.customCheckFileType === 'function') {
        return this.customCheckFileType(file);
      }
      if (this.isPicture) {
        return this.checkImageType(file);
      } else if (this.isVideo) {
        return this.checkVideoType(file);
      } else {
        return this.checkFileType(file);
      }
    },
    // 上传文件前的钩子函数
    beforeUpload(file) {
      return new Promise(async (resolve, reject) => {
        if (!this.checkUploadType(file)) {
          reject();
          return;
        }
        // 限制尺寸
        // if (this.limitDimensionsInfo) {
        //   try {
        //     await this.checkImageWH(file);
        //   } catch (error) {
        //     this.$message.warning(error);
        //     reject(error);
        //     return;
        //   }
        // }
        this.isShowSpinning = true;
        let newFile = file;
        if (this.isCompress) {
          newFile = await this.compressImg(file);
        }
        resolve(newFile);
      });
    },
    checkImageWH(file) {
      const that = this;
      return new Promise(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = (e) => {
          let src = e.target.result;
          const image = new Image();
          image.onload = () => {
            // if(image.width / image.height)
            if (that.limitDimensionsInfo.width && that.limitDimensionsInfo.height) {
              if (
                Number(image.width / image.height) >
                Number(that.limitDimensionsInfo.width / that.limitDimensionsInfo.height)
              ) {
                // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
                reject('图片宽高比需小于9:16');
                return;
              }
            }

            if (that.limitDimensionsInfo.scale) {
              console.log(
                that.limitDimensionsInfo.scale,
                Number(image.width / image.height),
                '---------',
              );
              if (that.limitDimensionsInfo.scale != Number(image.width / image.height)) {
                reject('上传图片不满足图片尺寸比例要求');
                return;
              }
            }
            if (that.limitDimensionsInfo.minWidth) {
              if (Number(image.width) < Number(that.limitDimensionsInfo.minWidth)) {
                reject('上传图片不满足最小分辨率要求');
                return;
              }
            }
            resolve();
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
    // 自定义的上传请求
    async customRequest(info) {
      return this.uploadToServer(info.file);
    },
    // base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // 图片压缩函数
    compressImg(file) {
      console.log('压缩');
      const that = this;
      var files;
      var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2);
      var read = new FileReader();
      read.readAsDataURL(file);
      return new Promise(function (resolve, reject) {
        read.onload = function (e) {
          var img = new Image();
          img.src = e.target.result;
          img.onload = function () {
            // 默认按比例压缩
            var w = this.width;
            var h = this.height;
            // 生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            var base64;
            if (fileSize < 1) {
              // 创建属性节点
              canvas.setAttribute('width', w);
              canvas.setAttribute('height', h);
              ctx.drawImage(this, 0, 0, w, h);
              base64 = canvas.toDataURL(file['type'], 0.5);
            } else if (fileSize > 1 && fileSize < 2) {
              // 创建属性节点
              canvas.setAttribute('width', w / 1.2);
              canvas.setAttribute('height', h / 1.2);
              ctx.drawImage(this, 0, 0, w / 1.2, h / 1.2);
              base64 = canvas.toDataURL(file['type'], 0.5);
            } else {
              // 创建属性节点
              canvas.setAttribute('width', w / 2);
              canvas.setAttribute('height', h / 2);
              ctx.drawImage(this, 0, 0, w / 2, h / 2);
              base64 = canvas.toDataURL(file['type'], 0.3);
            }
            // 回调函数返回file的值（将base64编码转成file）
            files = that.dataURLtoFile(base64, file.name); // 如果后台接收类型为base64的话这一步可以省略
            resolve(files);
          };
        };
      });
    },

    //预览
    showPreview(url) {
      if (!url) {
        this.$message.error('文件路径错误!');
        return;
      }
      this.previewUrl = this.fileList?.map((item) => item.url)?.filter((i) => this.isImgOrVideo(i));
      this.previewIndex = this.previewUrl.findIndex((i) => i == url);
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    //删除
    handleDelete(index) {
      this.fileList.splice(index, 1);
      if (this.getMultiple) {
        this.$emit('change', this.fileList);
      } else {
        this.$emit('change', '');
      }
    },
  },
  computed: {
    // 当前为图片上传模式
    isPicture() {
      return this.config.type === IMAGE_UPLOAD;
    },
    // 当前为视频上传模式
    isVideo() {
      return this.config.type === VIDEO_UPLOAD;
    },
    // 禁用上传按钮
    isUploadButtonDisabled() {
      return !(
        (this.getMultiple && this.fileList.length < this.getMaxCount && !this.disabled) ||
        (!this.getMultiple && !this.fileList.length)
      );
    },
    // 是否只读
    isReadonly() {
      return this.config.readonly ?? false;
    },
    getShowUploadList() {
      return false;
    },
    // 当前是否为text模式
    isText() {
      return this.config.type === 'text';
    },
    // 当前为是否照片墙展示模式
    isPictureCard() {
      return this.config.listType === 'picture-card';
    },
    getListType() {
      return this.config.listType ?? 'picture-card';
    },
    // 是否开启图片压缩
    isCompress() {
      return this.config.enableZip || this.enableZip;
    },
    // 最大数量
    getMaxCount() {
      return this.config.maxNum || this.maxNum;
    },
    // 是否多选
    getMultiple() {
      return this.config.multiple || this.multiple;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-upload {
  .content-wrapper {
    &.custom {
      display: flex;
    }
    /deep/ > span {
      display: block;
      .ant-upload {
        width: 100%;
      }
    }
  }
  .image-custom-background {
    width: 216px;
    height: 108px;
    flex: 0 0 216px;
    margin-right: 20px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;

    &:hover {
      .board-wrapper {
        visibility: visible;
      }
    }

    .custom-poster {
      width: 216px;
      height: 108px;
      position: relative;

      .file-upload-title {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        position: absolute;
        margin-bottom: 8px;
        transform: translate(-50%, -50%);
        z-index: 1;
        word-break: break-all;
        min-height: 20px;
        max-width: 100%;
        user-select: none;
        white-space: normal;
        line-height: 1.2;
      }
      .image-background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0px;
      }
    }
    .board-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      width: 65px;
      height: 100%;
      visibility: hidden;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .switch {
      position: absolute;
      top: 0;
      right: 10px;
      z-index: 1;
      color: #fff;
      cursor: pointer;
      padding: 2px;
    }
    .refresh {
      position: absolute;
      bottom: 0;
      right: 10px;
      padding: 2px;
      z-index: 1;
      color: #fff;
      cursor: pointer;
    }
  }

  .img-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.9s;
    .img-container {
      position: relative;
      margin-left: 4px;
      padding: 4px;
      width: 80px;
      height: 80px;
      border: 1px dashed #dcdcdc;
      box-sizing: border-box;
      .options {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($color: #000000, $alpha: 0);
        visibility: hidden;
        transition: all 0.3s;
        i {
          font-size: 24px;
          color: transparent;
        }
      }
      &:hover {
        .options {
          background: rgba($color: #000000, $alpha: 0.3);
          visibility: visible;
          i {
            color: white;
          }
        }
      }
      .tip {
        background: rgba($color: #000000, $alpha: 0.3);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: white;
      }

      .video-div {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0%;
          right: 0%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          background: url('https://file.ynzyq.net/frontend/static/fae102658c61dce6f089e31435397f04_48_48.png')
            no-repeat center;
          background-size: 100% auto;
          // z-index: 1;
        }
      }
    }
    .upload-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      padding: 4px;
      width: 80px;
      height: 80px;
      border: 1px dashed #e1deda;
      border-radius: 4px;
    }
  }
  .img {
    width: 100%;
    height: 100%;
  }
  .default-tips {
    font-size: 12px;
    color: #9a9a9a;
    margin-top: 13px;
  }

  & /deep/ .ant-upload.ant-upload-select-picture-card {
    width: auto;
    height: 100%;
    background-color: transparent;
    border: none;
    .ant-upload {
      padding: 0;
    }
  }
}

i {
  font-size: 32px;
  color: #999;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.normal {
  cursor: pointer;
}

.ant-upload-text {
  // margin-top: 8px;
  color: #666;
  text-align: center;
}
.file-list {
  display: flex;
  flex-wrap: wrap;
  .file-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .file-name {
      font-size: 12px;
      color: #666;
      margin-top: 8px;
      text-align: center;
    }
  }
}
.upload-btn-file {
  height: 80px;
  opacity: 1;

  background: #f5f5f5;
  border-radius: 4px;

  box-sizing: border-box;
  /* 功能色/中性色/Gray4-边框 */
  // border: 1px dashed #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    .link {
      color: #666;
      text-align: center;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.file-list-custom {
  width: 100%;
}
.file-list-item-wrapper {
  position: relative;
  display: flex;
  margin-top: 6px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 4px 28px 4px 32px;
  z-index: 0;
  width: 100%;

  .file-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
  }
  .file-names {
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    width: 0;
  }

  .remove-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    color: #ff6b00;
    font-size: 16px;
  }
}
.file-block-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  .file-block-item {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-right: 2px;
    flex: 0 0 30%;
    min-width: 240px;
    width: 0;
    padding: 4px 12px;
    margin-top: 6px;
    margin-bottom: 6px;
    /* 描边/2 */
    border: 1px solid #f0f2f5;
    .file-type-icon {
      width: 24px;
      height: 100%;
      margin-right: 12px;
      img {
        margin-top: 8px;
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
    .file-content {
      flex: 1 1 96px;
      height: 42px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      padding-top: 4px;

      /* 文字/辅助 */
      color: #6b7785;
      .file-bottom {
        margin-top: 2px;
      }
    }
    .file-download {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // width: 16px;
      height: 100%;
      margin-right: 4px;
      cursor: pointer;
      img {
        margin-left: 6px;
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
