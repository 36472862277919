<template>
  <div class="fast-text-wrapper">
    <!-- <TextCom v-if="isShowText" :config="configRef" :value="valueRef"></TextCom> -->
    <UserPicker v-if="isShowUserPicker" :config="configRef" :value="valueRef"></UserPicker>
    <div v-else-if="isShowDateTimeRangeText">{{ transformRange(valueRef) }}</div>
    <div v-else-if="isArea">{{ transformArea(valueRef) }}</div>
    <div v-else-if="isOptionSelect">{{ valueRef ? valueRef.label : '' }}</div>
    <div v-else-if="isInputMap">{{ valueRef?.[2] }}</div>
    <div v-else-if="isMultipleSelect">{{ (valueRef || []).join('，') }}</div>
    <div v-else style="word-break: break-all">{{ valueRef }}</div>
  </div>
</template>
<script setup>
import _ from 'lodash';
// 拿出attr和监听的事件
import { defineProps, toRefs, computed, watch } from 'vue';
import TextCom from './TextComponents/Text/index.vue';
import UserPicker from './TextComponents/TextUserPicker/index.vue';

const props = defineProps({
  value: {
    type: [String, Object, Number, Array],
    // default: '',
  },
  config: {
    type: Object,
    default: () => ({}),
  },
});

const { config: configRef, value: valueRef } = toRefs(props);
const oldType = configRef.value.oldType;
// 是否是从'TextInput', 'TextareaInput'变换过来的
const isShowText = computed(() => {
  return ['TextInput', 'TextareaInput'].includes(oldType);
});
// 是否是从userpicker中变换过来的
const isShowUserPicker = computed(() => {
  return ['UserPicker'].includes(oldType);
});

// 是否是从'DateTimeRange' 日期区间
const isShowDateTimeRangeText = computed(() => {
  return ['DateTimeRange'].includes(oldType);
});
// 是否是省市区
const isArea = computed(() => {
  return ['Provinces'].includes(oldType);
});
// 是否地图选点
const isInputMap = computed(() => {
  return ['InputMap'].includes(oldType);
});
// 是否开发组别
const isOptionSelect = computed(() => {
  return ['OptionSelect'].includes(oldType);
});
// 是否开多选框
const isMultipleSelect = computed(() => {
  return ['MultipleSelect'].includes(oldType);
});
const transformRange = (val) => {
  return val && val.length === 2 ? val.join(' ~ ') : '';
};
const transformArea = (val) => {
  return val ? val.join('/') : '';
};
</script>

<style lang="scss" scoped>
.fast-text-wrapper {
  // font-size: 14px;
  // line-height: 24px;
  // margin-bottom: 8px;
  // margin-top: 8px;
  img {
    width: 12px;
    height: 13px;
    margin-right: 4px;
  }
  span,
  img {
    vertical-align: middle;
  }
}
</style>
