// 迁移至 '@/pages/workflow/template/draw/formDesign/form/ComponentsConfigExport.js';
export const ValueType = {
  string: 'String',
  object: 'Object',
  array: 'Array',
  number: 'Number',
  date: 'Date',
  user: 'User',
  dept: 'Dept',
  dateTimeRange: 'DateTimeRange',
  place: 'Provinces',
};

export const baseComponents = [
  // {
  //   name: '布局',
  //   components: [
  //     {
  //       title: '分栏布局',
  //       name: 'SpanLayout',
  //       icon: 'el-icon-c-scale-to-original',
  //       value: [],
  //       valueType: ValueType.array,
  //       props: {
  //         items: [],
  //       },
  //     },
  //   ],
  // },
  {
    name: '基础组件',
    components: [
      {
        title: '单行文本输入',
        name: 'TextInput',
        icon: 'el-icon-edit',
        value: '',
        valueType: ValueType.string,
        props: {
          required: false,
          desc: '',
          enablePrint: true,
          nullVisible: false,
          disabledField: false,
        },
      },
      {
        title: '多行文本输入',
        name: 'TextareaInput',
        icon: 'imgUrl=https://file.ynzyq.net/frontend/static/comp-mulline.png',
        value: '',
        valueType: ValueType.string,
        props: {
          required: false,
          desc: '',
          enablePrint: true,
          nullVisible: false,
          disabledField: false,
        },
      },
      {
        title: '数字输入框',
        name: 'NumberInput',
        icon: 'el-icon-edit-outline',
        value: '',
        valueType: ValueType.number,
        props: {
          required: false,
          desc: '',
          enablePrint: true,
          nullVisible: false,
          disabledField: false,
        },
      },
      {
        title: '金额输入框',
        name: 'AmountInput',
        icon: 'el-icon-money',
        value: '',
        valueType: ValueType.number,
        props: {
          required: false,
          desc: '',
          enablePrint: true,
          nullVisible: false,
          disabledField: false,
          // showChinese: true,
        },
      },
      {
        title: '说明文字',
        name: 'Description',
        icon: 'el-icon-warning-outline',
        value: '',
        valueType: ValueType.string,
        props: {
          required: false,
          desc: '',
          nullVisible: false,
          disabledField: false,
          enablePrint: true,
          color: 'default',
          foldable: false, // 是否支持折叠 true支持折叠(只支持公共组件)
          defaultFold: false, // 默认折叠状态 true折叠(只支持公共组件)
        },
      },
      {
        title: '上传文件',
        name: 'FileUpload',
        icon: 'imgUrl=https://file.ynzyq.net/frontend/static/comp-fielupload.png',
        value: [],
        valueType: ValueType.array,
        props: {
          required: false,
          desc: '',
          nullVisible: false,
          disabledField: false,
          isShowExample: false,
          viewExampleUrl: [],
          enablePrint: true,
          onlyRead: false, //是否只读，false只能在线预览，true可以下载
          maxSize: 100, //文件最大大小MB
          maxNumber: 10, //最大上传数量
          fileTypes: [], //限制文件上传类型
        },
      },
      // {
      //   title: '上传图片',
      //   name: 'ImageUpload',
      //   icon: 'el-icon-picture-outline',
      //   value: [],
      //   valueType: ValueType.array,
      //   props: {
      //     required: false,
      //     nullVisible: false,
      //     disabledField: false,
      //     isShowExample: false,
      //     viewExampleUrl: [],
      //     enablePrint: true,
      //     maxSize: 10, //图片最大大小MB
      //     maxNumber: 10, //最大上传数量
      //     enableZip: true, //图片压缩后再上传
      //   },
      // },
      {
        title: 'OCR识别',
        name: 'OcrUpload',
        icon: 'el-icon-picture-outline',
        value: [],
        valueType: ValueType.object,
        props: {
          required: false,
          desc: '',
          nullVisible: false,
          disabledField: false,
          enablePrint: true,
          maxSize: 10, //图片最大大小MB
          maxNumber: 1, //最大上传数量
          enableZip: true, //图片压缩后再上传
          ocrProps: { id: '' },
        },
      },

      // {
      //   title: '上传视频',
      //   name: 'VideoUpload',
      //   icon: 'el-icon-video-camera',
      //   value: [],
      //   valueType: ValueType.array,
      //   props: {
      //     required: false,
      //     nullVisible: false,
      //     disabledField: false,
      //     isShowExample: false,
      //     viewExampleUrl: [],
      //     enablePrint: true,
      //     maxSize: 50, //视频最大大小MB
      //     maxNumber: 10, //最大上传数量
      //     // enableZip: true, //视频压缩后再上传
      //   },
      // },

      // {
      //   title: '开发组别',
      //   name: 'OptionSelect',
      //   icon: 'el-icon-user',
      //   value: [],
      //   valueType: ValueType.user,
      //   props: {
      //     required: false,
      //     nullVisible: false,
      //     hasDisabled: false,
      //     enablePrint: true,
      //     userPicker: [],
      //   },
      // },
    ],
  },
  {
    name: '日期组件',
    components: [
      {
        title: '日期时间点',
        name: 'DateTime',
        icon: 'el-icon-date',
        value: '',
        valueType: ValueType.date,
        props: {
          required: false,
          desc: '',
          nullVisible: false,
          disabledField: false,
          enablePrint: true,
          format: 'YYYY-MM-DD',
        },
      },
      {
        title: '日期时间区间',
        name: 'DateTimeRange',
        icon: 'imgUrl=https://file.ynzyq.net/frontend/static/comp-daterange.png',
        value: [],
        valueType: ValueType.dateTimeRange,
        props: {
          required: false,
          desc: '',
          nullVisible: false,
          disabledField: false,
          enablePrint: true,
          placeholderArr: ['开始时间', '结束时间'],
          format: 'YYYY-MM-DD',
          showLength: false,
        },
      },
    ],
  },
  {
    name: '选项组件',
    components: [
      {
        title: '单选框',
        name: 'SelectInput',
        icon: 'el-icon-circle-check',
        value: '',
        valueType: ValueType.string,
        props: {
          required: false,
          enablePrint: true,
          expanding: false,
          options: ['选项1', '选项2'],
          nullVisible: false,
          disabledField: false,
          isNewCompanySwitch: false,
        },
      },
      {
        title: '多选框',
        name: 'MultipleSelect',
        icon: 'imgUrl=https://file.ynzyq.net/frontend/static/comp-mulselect.png',
        value: [],
        valueType: ValueType.array,
        props: {
          required: false,
          enablePrint: true,
          expanding: false,
          options: ['选项1', '选项2'],
          nullVisible: false,
          disabledField: false,
          mode: 'multiple',
        },
      },
      {
        title: '人员选择',
        name: 'UserPicker',
        icon: 'el-icon-user',
        value: [],
        valueType: ValueType.user,
        props: {
          required: false,
          desc: '',
          nullVisible: false,
          disabledField: false,
          hasDisabled: false,
          enablePrint: true,
          userPicker: [],
        },
      },
      // {
      //   title: '地图选点',
      //   name: 'InputMap',
      //   icon: 'el-icon-map-location',
      //   value: [],
      //   valueType: ValueType.array,
      //   props: {
      //     required: false,
      //     enablePrint: true,
      //     nullVisible: false,
      //     disabledField: false,
      //   },
      // },
      {
        title: '省市区',
        name: 'Provinces',
        icon: 'el-icon-place',
        value: [],
        valueType: ValueType.place,
        props: {
          required: false,
          desc: '',
          nullVisible: false,
          disabledField: false,
        },
      },

      // {
      //   title: '部门选择',
      //   name: 'DeptPicker',
      //   icon: 'iconfont icon-map-site',
      //   value: [],
      //   valueType: ValueType.dept,
      //   props: {
      //     required: false,
      //     desc: '',
      //     enablePrint: true,
      //     multiple: false
      //   }
      // },
    ],
  },
  {
    name: '计算组件',
    components: [
      {
        title: '数字金额计算',
        name: 'ComputedExpression',
        icon: 'imgUrl=https://file.ynzyq.net/frontend/static/comp-numcalc.png',
        value: '',
        valueType: ValueType.string,
        props: {
          required: false,
          enablePrint: true,
          multiple: false,
          disabled: true,
          numberType: 1,
          expressionList: [],
        },
      },
      {
        title: '日期时间计算',
        name: 'ComputedDate',
        icon: 'imgUrl=https://file.ynzyq.net/frontend/static/comp-datecalc.png',
        value: '',
        valueType: ValueType.string,
        props: {
          required: false,
          enablePrint: true,
          multiple: false,
          disabled: true,
          numberType: 1,
          expressionList: [],
        },
      },
    ],
  },
  // {
  //   name: '扩展组件',
  //   components: [
  //     {
  //       title: '明细表',
  //       name: 'TableList',
  //       icon: 'el-icon-tickets',
  //       value: [],
  //       valueType: ValueType.array,
  //       props: {
  //         required: false,
  //         desc: '',
  //         enablePrint: true,
  //         showBorder: true,
  //         rowLayout: true,
  //         showSummary: false,
  //         summaryColumns: [],
  //         maxSize: 0, //最大条数，为0则不限制
  //         columns: [], //列设置
  //       },
  //     },
  //   ],
  // },
];
export default {
  baseComponents,
};
