import request from '@/libs/API_common.js';

//获取角色列表
export const getRolePageList = (data) =>
  request({
    url: '/om-auth/api/auth/org/role/pageList',
    method: 'post',
    noLoading: true,
    data,
  });

  //获取角色详情
export const getRoleDetail = (params) =>
  request({
    url: '/om-auth/api/auth/org/role/detail',
    method: 'get',
    noLoading: true,
    params,
  });
//获取角色详情
export const getRoleAuthUser = (params) =>
  request({
    url: '/om-auth/api/auth/org/role/getAuthUser',
    method: 'get',
    noLoading: true,
    params,
  });
// 新增/编辑 角色
export const saveRole = (data) =>
  request({
    url: '/om-auth/api/auth/org/role/save',
    method: 'post',
    noLoading: true,
    data,
  });

// 获取菜单树
export const getMenuTree = (params) =>
  request({
    url: '/om-auth/api/auth/app/menu/tree',
    method: 'get',
    params,
    noLoading: true,
  });
// 获取授权用户列表
export const getRoleUserList = (params) =>
  request({
    url: '/om-auth/api/auth/org/role/userList',
    method: 'get',
    params,
    noLoading: true,
  });
// 给角色批量授权用户
export const saveRoleUserList = (data) =>
  request({
    url: '/om-auth/api/auth/org/role/authorize',
    method: 'post',
    noLoading: true,
    data,
  });
// 获取应用权限
export const getAppsList = (params) =>
  request({
    url: '/om-auth/api/auth/app/pageList',
    method: 'get',
    noLoading: true,
    params,
  });
