<template>
  <div class="people-tree-select-wrapper">
    <template v-if="columns.length">
      <a-input-search
          allow-clear
          v-model="searchKey"
          placeholder="请输入搜索词"
          enter-button
          @search="onSearch"
          style="width: 100%; margin-bottom: 12px"
      ></a-input-search>
      <a-table
          :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectionChange,
        onSelect: onSelect,
        onSelectAll: onSelectAll,
      }"
          size="small"
          :scroll="{ y: 220 }"
          :pagination="pagination"
          :data-source="dataSource"
          :columns="columns"
          :row-key="(record) => record.id"
          @change="onChange"
      >
      </a-table>
    </template>
    <div class="is-empty" v-else>
      <a-empty />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      default() {
        return [];
      },
    },
    dataSource: {
      type: Array,
      default() {
        return [];
      },
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    pagination: {
      type: Object,
      default() {
        return {};
      },
    },
    data() {
      return {
        rowSelection: [],
      };
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return { selectedRowKeys: [], searchKey: '' };
  },
  methods: {
    clearSearchInput() {
      this.searchKey = '';
    },
    onSearch() {
      this.$emit('search', this.searchKey);
    },
    onSelectionChange(selectedRowKeys,selectedRows) {
      console.log('onSelectionChange', selectedRowKeys,selectedRows);
    },
    onSelect(row, selected, selectedRows, nativeEvent) {
      let ids = [];
      if (selected) {
        ids = this.value.concat([row.id]);
        this.$emit('check', [row]);
      } else {
        ids = this.value.filter((id) => id !== row.id);
        this.$emit('unCheck', [row]);
      }
      this.$emit('change', ids);
    },
    onSelectAll(selected, selectedRows, changeRows) {
      let ids = [];
      if (selected) {
        ids = this.value.concat(changeRows.map((item) => item.id));
        this.$emit('check', changeRows);
      } else {
        const changeIds = changeRows.map((item) => item.id);
        ids = this.value.filter((id) => !changeIds.includes(id));
        this.$emit('unCheck', changeRows);
      }
      this.$emit('change', ids);
    },
    onChange({ current: pageNo, pageSize }) {
      if (this.pagination?.change) {
        this.pagination.change({ pageNo, pageSize });
      }
    },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
  watch: {
    val: {
      handler(val) {
        this.selectedRowKeys = val;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.people-tree-select-wrapper {
  //border: 1px solid #d9d9d9;
  padding: 12px;
  border-radius: 4px;
  min-height: 320px;
}
.people-tree-select-search {
  margin-bottom: 10px;
}

.is-empty {
  padding-top: 80px;
}
</style>
