/**
 * 权限2.0-用户管理
 * @see http://yapi.infra.mzdata.top/project/268/interface/api/4247
 */
import request from '@/libs/API_common.js';
const baseUrl = '/om-auth/api/auth/org';

//获取组织架构树
export const getDeptAll = (data) =>
  request({
    url: `${baseUrl}/dept/all`,
    method: 'post',
    noLoading: true,
    data,
  });

//获取部门列表
export const getDeptList = (data) =>
  request({
    url: `${baseUrl}/dept/list`,
    method: 'post',
    noLoading: true,
    data,
  });

//获取职位列表
export const getPostList = (data) =>
  request({
    url: `${baseUrl}/post/list`,
    method: 'post',
    noLoading: true,
    data,
  });

//查询部门下的人
export const getStaffByDept = (data) =>
  request({
    url: `${baseUrl}/staff/pageList`,
    method: 'post',
    noLoading: true,
    data,
  });
//保存部门信息
export const saveDept = (data) =>
  request({
    url: `${baseUrl}/dept/save`,
    method: 'post',
    noLoading: true,
    data,
  });

//删除部门
export const deleteDept = (params) =>
  request.get(`${baseUrl}/dept/delete`, {
    params: params,
  });

//获取门店列表
export const getAuthStoreListV2 = (data) =>
  request.get(`${baseUrl}/store/list`, {
    params: data,
  });

//获取用户角色
export const getDeptRoles = (data) =>
  request.get(`${baseUrl}/role/list`, {
    params: data,
  });

//保存用户
export const saveUser = (data) =>
  request({
    url: `${baseUrl}/user/save`,
    method: 'post',
    noLoading: true,
    data,
  });

//授权门店
export const userAuthorizeStores = (data) =>
  request({
    url: `${baseUrl}/user/authorize`,
    method: 'post',
    noLoading: true,
    data,
  });

//修改密码
export const updatePassword = (data) =>
  request({
    url: `${baseUrl}/user/password/update`,
    method: 'post',
    noLoading: true,
    data,
  });

//获取是否配置了第三方配置
export const getSysConfig = (data) =>
  request.get(`/om-auth/api/auth/config/getByKey`, {
    params: data,
  });

//同步组织架构数据
export const flushOrg = (data) =>
  request({
    url: `${baseUrl}/flush`,
    method: 'get',
    noLoading: true,
    data,
  });

//修改用户状态
export const changeUserStats = (data) =>
  request.get(`${baseUrl}/user/enable`, {
    params: data,
  });
//获取当前是从哪个第三方app同步进来的数据
export const getCurrentTenantThirdAppType = (data) =>
  request.get('/om-auth/api/auth/getCurrentTenantThirdAppType');

// 下载导入批量授权的模板
export const downUserImportTemplate = (params) =>
  request.get('/om-auth/api/auth/org/user/authorize/download', {
    params: params,
    responseType: 'blob',
  });
//店长库列表
export const shopownerPageList = (data) =>
  request({
    url: `/om-auth/api/auth/shopowner/pageList`,
    method: 'post',
    noLoading: true,
    data,
  });
//店长区域树
export const shopownerTree = (data) => request.get('/om-auth/api/auth/shopowner/areaTree');
//同步店长库
export const shopownerSync = (data) => request.get('/om-auth/api/auth/shopowner/sync');
//同步店长师傅
export const syncLocal = (data) => request.get('/om-auth/api/auth/org/shop/syncLocal');
//同步施工方
export const supplierSync = (data) =>
  request({
    url: `/om-auth/api/auth/franchisee/supplierSync`,
    method: 'post',
    data,
  });
