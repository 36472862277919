<template>
  <div class="mask-div-out">
    <div class="mask-div" @click="close">
      <div @click.stop="close" class="close-icon-div">
        <a-icon type="close-circle" class="close-icon" />
      </div>
      <a-icon
        type="left"
        class="changeImg left-icon"
        :class="changeColor == 0 ? 'disabled' : ''"
        @click.stop="handleCurrentChange(-1)"
      />
      <a-icon
        type="right"
        @click.stop="handleCurrentChange(1)"
        class="changeImg right right-icon"
        :class="changeColor == imgList.length - 1 ? 'disabled' : ''"
      />
      <div class="index-div" @click.stop="stopPrevent">
        <span>{{ changeColor + 1 }}</span> /
        <span>{{ imgList.length }}</span>
      </div>
      <div id="test_3">
        <div class="video-show-div" v-if="$isVideoTest(furl) && videoShow">
          <video
            @mousedown="start"
            @click.stop="stopPrevent"
            @mousemove="move"
            @mouseup="stop"
            @mouseleave="mouseLeave"
            controls
            ref="singleVideo"
            width="100%"
            height="100%"
            class="originStyle"
          >
            <source :src="furl" type="video/mp4" />
          </video>
        </div>

        <img
          v-else
          @click.stop="stopPrevent"
          @mousedown="start"
          @mousemove="move"
          @mouseup="stop"
          @mouseleave="mouseLeave"
          :src="furl"
          ref="singleImg"
          class="originStyle"
        />
      </div>

      <div class="footer-btn">
        <div class="ctr-box" @click.stop="stopPrevent">
          <a-button @click="rotateL" title="左旋转">
            <a-icon type="redo" />
          </a-button>
          <a-button @click="rotateR" title="右旋转">
            <a-icon type="undo" />
          </a-button>
          <a-button @click="scale(-1)" title="缩小">
            <a-icon type="zoom-out" />
          </a-button>
          <a-button @click="scale(1)" title="放大">
            <a-icon type="zoom-in" />
          </a-button>
          <!-- <a-button @click="download" title="下载">
          <a-icon type="download" />
        </a-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "showImgAndVideoMask",
  data() {
    return {
      num: 0,
      furl: "",
      changeColor: 0,
      currentRotate: 0,
      currentScale: 1,
      canDrag: false,
      offset_x: 0,
      offset_y: 0,
      mouse_x: 0,
      mouse_y: 0,
      moduleCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "480px",
      },
      downUrl: "",
      videoShow: false
    };
  },
  props: {
    title: {
      //弹框名称
      type: String,
      required: false,
      default: "图片预览"
    },
    filePreviewShow: {
      //是否显示
      type: Boolean,
      required: true,
      default: true
    },
    imgList: {
      //图像数组
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    currentImg: {
      //当前图像
      type: String,
      required: false,
      default: ""
    },
    currentIndex: {
      //当前图像下标
      type: Number,
      required: false,
      default: 0
    },
    defaultScaleSize: {
      type: Number,
      default: 0.3
    },
    //层级
    zIndex: {
      type: Number,
      default: 100000
    }
  },
  watch: {
    filePreviewShow(newv) {
      // console.log('show-filePreviewShow', newv)
      if (newv) {
        this.imgList = this.trimSpace(this.imgList);
        if (this.imgList.length === 0) {
          this.furl = "";
          this.changeColor = -1;
        } else {
          this.changeColor = this.currentIndex;
          if (this.currentImg) {
            this.changeColor = this.imgList.findIndex(
              a => a === this.currentImg
            );
          }
          this.furl = this.imgList[this.changeColor];
          this.handlerImg(this.furl, this.changeColor);
        }
      } else {
        window.removeEventListener("mousewheel", this.handleScroll, true) ||
          window.removeEventListener("DOMMouseScroll", this.handleScroll, true);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.handlerImg(this.imgList[this.currentIndex], this.currentIndex);
    }, 500);
    window.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (e.keyCode === 27) {  // 判断是否是esc
        this.close();
      }
    },
    stopPrevent() {
      return
    },
    close() {
      this.$emit('close')
    },
    trimSpace(array) {
      for (var i = 0; i < array.length; i++) {
        if (
          array[i] == " " ||
          array[i] == null ||
          typeof array[i] == "undefined"
        ) {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },
    //判断滚动方向
    handleScroll(e) {
      this.scale(-e.deltaY);
    },
    //点击图片显示
    handlerImg(furl, index) {
      this.currentRotate = 0;
      this.currentScale = this.defaultScaleSize;
      this.rotateScale();
      if (this.$refs.singleImg) {
        this.$refs.singleImg.style.left = 0;
        this.$refs.singleImg.style.top = 0;
      }
      if (this.$refs.singleVideo) {
        this.$refs.singleVideo.style.left = 0;
        this.$refs.singleVideo.style.top = 0;
      }
      this.furl = furl;
      this.changeVideo(this.furl)
      this.changeColor = index;
      if (
        document.getElementsByClassName("originStyle") &&
        document.getElementsByClassName("originStyle")[0] &&
        document.getElementsByClassName("originStyle")[0].style
      ) {
        document.getElementsByClassName("originStyle")[0].style.position =
          "relative";
      }
    },
    changeVideo(url) {
      if (!url) return
      if (this.$isVideoTest(url)) {
        this.videoShow = false
        setTimeout(() => {
          this.videoShow = true
        })
      }
    },
    handleCurrentChange(val) {
      const changeColor = this.changeColor + val;
      if (changeColor === this.imgList.length || changeColor === -1) {
        return;
      }
      this.currentRotate = 0;
      this.currentScale = this.defaultScaleSize;
      this.rotateScale();
      if (this.$refs.singleImg) {
        this.$refs.singleImg.style.left = 0;
        this.$refs.singleImg.style.top = 0;
        this.$refs.singleImg.style.position = "relative";
      }
      if (this.$refs.singleVideo) {
        this.$refs.singleVideo.style.left = 0;
        this.$refs.singleVideo.style.top = 0;
        this.$refs.singleVideo.style.position = "relative";
      }
      this.furl = this.imgList[changeColor];
      this.changeVideo(this.furl)
      this.changeColor = changeColor;
      const noScroll =
        (val > 0 && changeColor <= 5) ||
        (val < 0 && changeColor + 1 >= this.imgList.length - 5);
    },
    start(e) {
      //鼠标左键点击
      e.preventDefault && e.preventDefault(); //去掉图片拖动响应
      if (e.button == 0) {
        this.canDrag = true;
        //获取需要拖动节点的坐标
        this.offset_x = document.getElementsByClassName(
          "originStyle"
        )[0].offsetLeft; //x坐标
        this.offset_y = document.getElementsByClassName(
          "originStyle"
        )[0].offsetTop; //y坐标
        //获取当前鼠标的坐标
        this.mouse_x = e.pageX;
        this.mouse_y = e.pageY;
      }
    },
    move(e) {
      e.preventDefault && e.preventDefault();
      window.addEventListener("mousewheel", this.handleScroll, true) ||
        window.addEventListener("DOMMouseScroll", this.handleScroll, false);
      if (this.canDrag == true) {
        let _x = e.pageX - this.mouse_x;
        let _y = e.pageY - this.mouse_y;
        //设置移动后的元素坐标
        let now_x = this.offset_x + _x + "px";
        let now_y = this.offset_y + _y + "px";
        document.getElementsByClassName("originStyle")[0].style.position =
          "absolute";
        document.getElementsByClassName("originStyle")[0].style.top = now_y;
        document.getElementsByClassName("originStyle")[0].style.left = now_x;
      }
    },
    mouseLeave(e) {
      e.preventDefault && e.preventDefault();
      this.canDrag = false;
      window.removeEventListener("mousewheel", this.handleScroll, true) ||
        window.removeEventListener("DOMMouseScroll", this.handleScroll, true);
    },
    stop(e) {
      e.preventDefault && e.preventDefault();
      this.canDrag = false;
    },
    //旋转放大
    rotateScale() {
      if (this.$refs.singleImg) {
        this.$refs.singleImg.style.Transform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
        this.$refs.singleImg.style.webkitTransform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
        this.$refs.singleImg.style.MozTransform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
        this.$refs.singleImg.style.msTransform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
        this.$refs.singleImg.style.transform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
      }
      if (this.$refs.singleVideo) {
        this.$refs.singleVideo.style.Transform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
        this.$refs.singleVideo.style.webkitTransform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
        this.$refs.singleVideo.style.MozTransform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
        this.$refs.singleVideo.style.msTransform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
        this.$refs.singleVideo.style.transform =
          "rotate(" +
          this.currentRotate +
          "deg)" +
          "scale(" +
          this.currentScale +
          ")";
      }

    },
    //旋转
    rotateL() {
      this.currentRotate += 15;
      this.rotateScale();
    },
    rotateR() {
      this.currentRotate -= 15;
      this.rotateScale();
    },
    //缩放 放大
    scale(type) {
      if (type >= 0) {
        this.currentScale += 0.1;
        this.rotateScale();
      } else {
        this.currentScale -= 0.1;
        if (this.currentScale <= 0.1) {
          this.currentScale = 0.1;
          this.rotateScale();
        } else {
          this.rotateScale();
        }
      }
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.src = imgsrc + "?v=" + Math.random(); // 处理缓存
      image.crossOrigin = "*"; // 支持跨域图片
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "img"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
    },
    download() {
      this.downloadIamge(this.furl, new Date().getTime());
    }
  }
};
</script>
<style lang="less" scoped >
.ctr-box {
  display: flex;
  justify-content: center;
  align-content: center;
  > button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.originStyle {
  // position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
#test_3 {
  position: relative;
  width: 80%;
  height: 88%;
  transform: translateY(7%);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  // pointer-events: none;
  margin: auto;
  .video-show-div {
    width: 80%;
    height: 80%;
  }
  img {
    cursor: move;
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }
}
.disabled {
  cursor: not-allowed;
}
.mask-div-out {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.mask-div {
  position: relative;
  width: 100%;
  height: 100%;
}
.close-icon-div {
  position: absolute;
  right: 30px;
  top: 20px;
  color: #fff;
  width: 50px;
  height: 50px;
  cursor: pointer;
  .close-icon {
    font-size: 50px;
  }
}
.left-icon {
  font-size: 50px;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.right-icon {
  font-size: 50px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.footer-btn {
  position: absolute;
  bottom: 0;
  background: rgba(102, 102, 102, 0.1);
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}
.index-div {
  position: absolute;
  height: 20px;
  line-height: 20px;
  color: #fff;
  font-size: 20px;
  z-index: 10000;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
</style>