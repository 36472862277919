<template>
  <div class="fast-switch-wrapper">
    <a-switch :checked="value" v-bind="$attrs" v-on="$listeners" />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      default() {
        return '';
      },
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.fast-switch-wrapper {
}
</style>
