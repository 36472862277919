// 自定义组件
export const TREE_SELECT = 'treeSelect'; // 树形下拉选择器
export const INPUT = 'input'; // 输入框
export const SEARCH_INPUT = 'searchInput'; // 搜索框
export const COMPUTEDEXPRESS = 'ComputedExpression'; // 计算公式组件
export const BUTTON = 'button'; // 按钮
export const RADIO = 'radio'; // 单选按钮
export const FILE_UPLOAD = 'fileUpload'; // 文件/图片上传
export const IMAGE_UPLOAD = 'imageUpload'; // 图片上传
export const OCR_UPLOAD = 'ocrUpload'; // ocr上传组件
export const INPUT_MAP = 'inputMap'; // 地图选点组件
export const VIDEO_UPLOAD = 'videoUpload'; // 视频上传
export const SELECT = 'select'; // 下拉搜索
export const EDITOR = 'editor'; // 富文本编辑
export const TEXTAREA = 'textarea'; // 文本域编辑
export const TEXT = 'text'; // 文本展示组件
export const KEY_CODE_ENTER = 'Enter'; // 回车按键code
export const INPUT_NUMBER = 'inputNumber'; // 数组输入框
export const INPUT_PASSWORD = 'inputPassword'; // 密码输入框
export const RANGE_PICKER = 'rangePicker'; // 日期时间区间选择
export const DATE_PICKER = 'datePicker'; // 日期时间选择器
export const TIME_PICKER = 'timePicker'; // 时间选择器
export const SWITCH = 'switch'; // switch开关
export const CHECKBOX = 'checkbox'; // 复选框
export const PEOPLE_SELECT = 'peopleSelect'; // 人员选择器
export const DESCRIPTION = 'description'; // 文字说明
export const AREA = 'Provinces'; // 省市区
