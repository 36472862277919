import Request from './request';
const baseURL = '/api/college';

export const instance = new Request(baseURL);
export const instance_form = new Request(baseURL, false, {
  'Content-Type': 'application/x-www-form-urlencoded',
});

const responseErrorHandle = (response) => {
  const code = response?.data?.code ?? 0;
  const msg = response?.data?.message ?? '';
  if (code === 10000) {
    window.vm.$message.error(msg);

    return Promise.reject(response);
  } else {
    return null;
  }
};

const courseBeforeRequest = (config) => {
  return config;
};

const courseBeforeResponse = (response) => {
  const errorRes = responseErrorHandle(response);
  if (errorRes) {
    return errorRes;
  } else {
    return response;
  }
};

instance.interceptors.request.use(courseBeforeRequest);
instance.interceptors.response.use(courseBeforeResponse);

export default {
  instance,
  instance_form,
};
