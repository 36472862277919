<template>
  <div class="fast-slot-wrapper">
    <slot
      :name="config.scopedSlots.customRender"
      v-bind:value="this.value"
      v-bind:setValue="this.setValue"
      v-bind:config="this.config"
    ></slot>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: ['value', 'config'],
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
  methods: {
    setValue(val) {
      this.val = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.fast-slot-wrapper {
  // width: min-content;
}
</style>
