import { BA_MAN_IMAGE } from './bamanImages.js';
import { BASE_IMAGE } from './baseImages.js';
import store from '@/store/index.js';
const BAMAN = 42;

// 如果当前租户为霸蛮，使用霸蛮图片
let images = BASE_IMAGE;

switch (store.state.tenant.tenant.tenantId) {
  case BAMAN:
    images = BA_MAN_IMAGE;
    break;

  default:
    images = BASE_IMAGE;
    break;
}

export default images;
