<template>
  <a-modal v-model="visible" title="添加常用意见" @ok="handleOk" @cancel="handleCancel">
    <el-form :model="form" :rules="rules" ref="modalForm">
      <el-form-item prop="reason" label="常用意见">
        <div style="position: relative">
          <el-input type="textarea" :rows="6" maxlength="200" v-model="form.reason"></el-input>
          <!-- <div style="position: absolute; right: 0; bottom: -34px">
            {{ `${form?.reason?.length} / 200` }}
          </div> -->
        </div>
      </el-form-item>
    </el-form>
  </a-modal>
</template>

<script>
export default {
  name: 'SuggestionModal',
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    modalVisible: {
      handler(val) {
        this.visible = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      form: {
        reason: '',
      },
      rules: {
        reason: [{ required: true, message: '请输入审批意见', trigger: 'blur' }],
      },
    };
  },
  created() {
    if (this.item) {
      this.form.reason = this.item.content;
    }
  },
  methods: {
    handleOk() {
      this.$refs.modalForm.validate((valid) => {
        if (valid) {
          this.$emit('confirm', { ...(this.item ?? {}), reason: this.form.reason });
          this.visible = false;
        }
      });
    },
    handleCancel() {
      this.$emit('cancel');
      this.visible = false;
    },
  },
};
</script>
