<template>
  <div class="tree-select-wrapper">
    <a-tree-select
      v-model="val"
      :show-search="isShowSearch"
      :filter-tree-node="onFilter"
      option-filter-prop="subs"
      :checkable="checkable"
      :selectable="selectable"
      :placeholder="placeholder"
      search-placeholder="搜索需要的内容"
      style="width: 100%"
      :allowClear="allowClear"
      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
      :tree-data="treeData"
      :replace-fields="replaceFields"
      :tree-default-expanded-keys="treeExpandedKeys"
    >
    </a-tree-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default() {
        return null;
      },
    },
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {},
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    treeExpandedKeys() {
      const fastItem = this.treeData[0] ?? {};
      return [fastItem?.[this.replaceFields.value]];
    },
    allowClear() {
      return this.config?.allowClear ?? false;
    },
    replaceFields() {
      const config = {
        title: 'name',
        key: 'bizId',
        children: 'subs',
        value: 'bizId',
      };
      return this.config?.replaceFields
        ? { ...config, ...this.config.replaceFields }
        : {
            title: 'name',
            key: 'bizId',
            children: 'subs',
            value: 'bizId',
          };
    },
    checkMethod() {
      return this.config?.checkMethod || 'checkbox';
    },
    isShowSearch() {
      return this.config?.showSearch ?? true;
    },
    isRadio() {
      return this.checkMethod === 'radio';
    },
    treeData() {
      return this.config?.options || [];
    },
    checkable() {
      return typeof this.config?.checkable === 'boolean' ? this.config?.checkable : false;
    },
    selectable() {
      return typeof this.config?.selectable === 'boolean' ? this.config?.selectable : true;
    },
    placeholder() {
      return this.config?.placeholder ?? '请选择下拉内容';
    },
    multiple() {
      return this.config?.multiple ?? false;
    },
  },
  methods: {
    onFilter(inputValue, treeNode) {
      const { componentOptions = {} } = treeNode;
      console.log(componentOptions?.propsData?.title, inputValue);
      return componentOptions?.propsData?.title.indexOf(inputValue) > -1;
    },
    onOk() {
      this.$emit('change', this.val);
      this.closeModal();
    },
    onCancel() {
      this.closeModal();
    },
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tree-select-wrapper {
  min-width: 120px;
}
.show-modal-btn {
  padding-left: 12px;
  height: 32px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  width: max-content;
  color: #aaa;
  cursor: pointer;
}
</style>
