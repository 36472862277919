<template>
  <div class="file-upload-wrapper">
    <a-upload
      :showUploadList="false"
      :accept="accept"
      :customRequest="onUpload"
      :disabled="uploading"
    >
      <div v-show="!hasPercent || !uploading" class="show-modal-btn" ref="show-modal-btn">
        <slot name="default">
          {{ getTitle || config.placeholder || '从本地上传' }}
        </slot>
      </div>
      <div class="progress" v-show="hasPercent && uploading">
        <a-progress :percent="percent" size="small" status="active" />
      </div>
    </a-upload>
  </div>
</template>

<script>
import {
  createUploadVideo,
  uploadCourseFile,
  refreshUploadVideo,
} from '@/api/mzSchool/courseManage';
// import MzUploader from '/process.env.BASE_URL/aliyunSDK/index.js';
import _ from 'lodash';
export default {
  props: {
    config: {
      type: Object,
    },
    value: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      defaultTitle: '',
      percent: 0,
      lastPercent: 0,
      hasPercent: false,
      uploading: false,
      uploader: null,
    };
  },
  computed: {
    progressPercent() {
      return this.percent + this.lastPercent;
    },
    accept() {
      return this.config?.accept ?? '';
    },
    getTitle() {
      return this.title || this.defaultTitle;
    },
    defaultConfig() {
      return {
        //userID，必填，您可以使用阿里云账号访问账号中心（https://account.console.aliyun.com/），即可查看账号ID
        userId: '269993476014268173',
        region: 'cn-beijing', //上传到视频点播的地域，默认值为'cn-shanghai'，     //eu-central-1，ap-southeast-1
        partSize: 102401, //分片大小默认1 MB，不能小于100 KB（100*1024）
        parallel: 3, //并行上传分片个数，默认5
        retryCount: 3, //网络原因失败时，重新上传次数，默认为3
        retryDuration: 2, //网络原因失败时，重新上传间隔时间，默认为2秒
        //开始上传
        onUploadstarted(uploadInfo) {
          // 这里会有初始化操作
        },
        //文件上传成功
        onUploadSucceed(uploadInfo) {},
        //文件上传失败
        onUploadFailed(uploadInfo, code, message) {},
        //文件上传进度，单位：字节
        onUploadProgress(uploadInfo, totalSize, loadedPercent) {},
        //上传凭证或STS token超时
        onUploadTokenExpired(uploadInfo) {
          console.log('上传超时');
        },
        //全部文件上传结束
        onUploadEnd(uploadInfo) {},
      };
    },
    // 视频类型文件
    FILE_TYPE_VIDEO() {
      return '1';
    },
  },
  methods: {
    // 获得资源url
    getObjectURL(file) {
      let url = null;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    // 获得视频长度信息
    getVideoInfo(videoFile) {
      return new Promise((resolve, reject) => {
        const url = this.getObjectURL(videoFile);
        let video = document.createElement('video');
        video.preload = 'metadata';
        video.onerror = () => {
          const length = 100;
          const name = videoFile.name;
          const type = videoFile.type;
          resolve({ length, name, type });
          video = null;
        };
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          const length = video.duration;
          const name = videoFile.name;
          const type = videoFile.type;
          resolve({ length, name, type });
          video = null;
        };
        video.src = url;
      });
    },
    uploadConfig() {
      return {
        ...this.defaultConfig,
        onUploadstarted: (uploadInfo) => {
          const { file, videoId = '' } = uploadInfo;
          const fileName = file.name;
          if (videoId) {
            refreshUploadVideo({ videoId }).then((res) => {
              const data = res?.data?.data;
              this.uploader.setUploadAuthAndAddress(
                uploadInfo,
                data.uploadAuth,
                data.uploadAddress,
                data.videoId,
              );
            });
          } else {
            createUploadVideo({ fileName })
              .then((res) => {
                const data = res.data.data;
                this.uploader.setUploadAuthAndAddress(
                  uploadInfo,
                  data.uploadAuth,
                  data.uploadAddress,
                  data.videoId,
                );
              })
              .catch((error) => {
                console.log(error);
              });
          }
        },
        onUploadTokenExpired(uploadInfo) {
          // 上传超时后刷新
          refreshUploadVideo({ videoId: uploadInfo?.videoId }).then((res) => {
            const data = res?.data?.data;
            this.uploader.resumeUploadWithAuth(
              uploadInfo,
              data.uploadAuth,
              data.uploadAddress,
              data.videoId,
            );
          });
        },
        onUploadProgress: (uploadInfo, totalSize, loadedPercent) => {
          // 操作进度条
          if (loadedPercent < 1) {
            this.percent = _.floor(loadedPercent * 100);
          }
        },
        onUploadSucceed: (uploadInfo) => {
          const { videoId, file } = uploadInfo;
          // 通过video的file获得视频信息
          this.percent = 100;
          this.uploading = false;
          this.getVideoInfo(file).then((videoInfo) => {
            videoInfo.videoId = videoId;
            this.$emit('change', videoInfo);
          });
        },
        onUploadEnd: () => {
          this.uploader.stopUpload();
          this.uploader = null;
        },
        onUploadFailed(uploadInfo, code, message) {
          console.log('上传失败');
        },
      };
    },
    reset() {
      this.defaultTitle = '';
    },
    onUpload(file) {
      if (file?.file?.type?.startsWith('video')) {
        this.onVideoUpload(file);
      } else {
        this.onFileUpload(file);
      }
    },
    onVideoUpload({ file }) {
      this.percent = 0; // 重新置0
      this.uploading = true;
      // start uploader
      // eslint-disable-next-line no-undef
      const MzUploader = AliyunUpload.Vod;
      this.uploader = new MzUploader(this.uploadConfig());
      this.uploader.addFile(file, null, null, null, '{"Vod":{}}');
      this.uploader.startUpload();
    },
    onFileUpload({ file }) {
      const formData = new FormData();
      formData.append('file', file);
      this.uploading = true;
      this.percent = 0; // 重新置0
      this.lastPercent = 0;
      uploadCourseFile(formData, {
        noLoading: true,
        onUploadProgress: (progressEvent) => {
          // 处理原生进度事件
          if (progressEvent.lengthComputable) {
            const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            if (complete <= 99) {
              this.percent = complete;
            }
          }
        },
      })
        .then((res) => {
          const data = res?.data?.data || {};
          this.defaultTitle = file.name;
          const url = data?.url || '';
          this.$emit('change', data);
          this.uploading = false;
          this.lastPercent = 1;
        })
        .catch((err) => {
          console.log(err);
          this.defaultTitle = '';
        });
    },
  },
  mounted() {
    this.hasPercent = this.$el.getAttribute('percent') !== null;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/antD/index.scss';
.show-modal-btn {
  padding-left: 12px;
  height: 32px;
  line-height: 32px;
  text-align: left;
  color: #5087ec;
  text-decoration: underline;
  cursor: pointer;
  & > span {
    @include line-clamp(2);
  }
}
.file-upload-wrapper-tips-placeholder {
  height: 50px;
}
.progress {
  width: 160px;
}
.file-upload-wrapper-tips {
  position: absolute;
  white-space: nowrap;
  width: auto;
  font-size: 12px;
  color: #9a9a9a;
  margin-top: -10px;
  p {
    height: 24px;
    line-height: 24px;
    margin-bottom: 0px;
  }
}
</style>
