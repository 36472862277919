import { baseComponents } from './form/ComponentsConfigExport';
export const computedUnitMap = [
  { desc: '保留小数点两位', label: '', value: '1', unit: '' },
  { desc: '百分比(保留小数点后两位)', label: '%', value: '2', unit: '%' },
  { desc: '整数', label: '', value: '3', unit: '' },
];
export const computedDateUnitMap = [
  { desc: 'X年(保留小数点后1位)', label: '年', value: '1', unit: 'year' },
  { desc: 'X月(保留小数点后1位)', label: '月', value: '2', unit: 'month' },
  { desc: 'X天(保留小数点后1位)', label: '天', value: '3', unit: 'day' },
];
export const removeUnitLabel = (str) => {
  return str.replace('年', '').replace('月', '').replace('天', '').replace('%', '');
};
export const isAsyncComp = ['ComputedExpression', 'ComputedDate'];
export const canLinkedFields = [
  'TextInput',
  'TextareaInput',
  'NumberInput',
  'AmountInput',
  'SelectInput',
  'DateTime',
  'ComputedExpression',
  'ComputedDate',
];
export const removeDigitsAndPrefix = (input) => {
  if (!input || input.trim() === '') {
    return input;
  }

  // 匹配数字和-前缀的正则表达式
  const pattern = /^[\d\s-]/;

  let result = '';

  for (let i = 0; i < input.length; i++) {
    if (pattern.test(input[i])) {
      continue;
    } else {
      result += input.slice(i);
      break;
    }
  }

  return result;
};
export const findDuplicateTitles = (datas) => {
  const titles = new Set();
  const duplicates = [];
  datas.forEach((i) => {
    if (titles.has(i)) {
      duplicates.push(i);
    } else {
      titles.add(i);
    }
  });
  return duplicates;
};

export const findModifiedTitles = (data) => {
  return data.filter((item) => item.title === item.cTitle).map((i) => i.title);
};
const fullConditionMap = {
  '==': '等于',
  '>': '大于',
  '<': '小于',
  '>=': '大于等于',
  '<=': '小于等于',
  '!=': '不等于',
};
const fullConditionOptions = Object.keys(fullConditionMap).map((key) => ({
  label: fullConditionMap[key],
  value: key,
}));
const basicConditionOptions = ['==', '!='].map((key) => ({
  label: fullConditionMap[key],
  value: key,
}));
export const conditionOptionsMap = {
  ComputedExpression: fullConditionOptions,
  ComputedDate: fullConditionOptions,
  DateTime: fullConditionOptions,
  NumberInput: fullConditionOptions,
  AmountInput: fullConditionOptions,
  SelectInput: basicConditionOptions,
  TextInput: basicConditionOptions,
  TextareaInput: basicConditionOptions,
};
export const getField = (fieldId, fieldOptions) => {
  const field = fieldOptions.find((option) => option.value === fieldId);
  return field ?? {};
};
export const getFieldType = (fieldId, fieldOptions) => {
  const field = fieldOptions.find((option) => option.value === fieldId);
  return field?.name ?? '';
};
export const getFieldTypeName = (fieldId, fieldOptions) => {
  const fieldType = getFieldType(fieldId, fieldOptions);
  const components = baseComponents.flatMap((componentGroup) => componentGroup.components);
  const component = components.find((comp) => comp.name === fieldType);
  return component?.title ?? '';
};
//生成一个全局唯一id
export const generateId = () => {
  return Math.random().toString(36).substr(2, 9);
};
