<template>
  <div class="course-select-wrapper">
    <div class="selected-title">已选</div>
    <div class="course-select-content">
      <div class="course-select-labels" v-for="(item, index) in valueRef" :key="index">
        <div class="course-select-labels-icon">
          <img :src="currentImageUrl(item.type)" alt="icon" />
        </div>
        <div class="course-select-labels-text">{{ item.name }}</div>
        <div class="course-select-labels-close-wrapper">
          <!-- <div class="course-select-labels-close" @click="del(item, index)"></div> -->
          <a-icon type="close" class="course-select-labels-close" @click="del(item, index)" />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="count">
        <!-- 共{{ deptCount }}个部门（包含其子部门）；{{ userCount }}个人员；{{ postCount }}个职位 -->
      </div>
      <div class="course-select-clear" @click="onClear">清空</div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs, watch, defineEmits } from 'vue';
const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['change', 'del']);

const del = (item, index) => {
  emit('delete', item);
};

const onClear = () => {
  emit('deleteAll');
};

const { value: valueRef } = toRefs(props);

const deptCount = computed(() => {
  return valueRef.value.filter((item) => item.type === 'DEPT').length;
});
const userCount = computed(() => {
  return valueRef.value.filter((item) => item.type === 'STAFF').length;
});
const postCount = computed(() => {
  return valueRef.value.filter((item) => item.type === 'POST').length;
});

// 根据类型返回图片icon
const currentImageUrl = (type) => {
  switch (type) {
    case 'DEPT':
      return 'https://file.ynzyq.net/frontend/static/3f8cead05c1a6c2cd4d34136ffd147cb.svg';
    case 'STAFF':
      return 'https://file.ynzyq.net/frontend/static/f2bfb43b95022c53aefa646abac47bb2.svg';
    case 'ROLE':
      return 'https://file.ynzyq.net/frontend/static/e73447a822c00e1b5706e48c9570318c.svg';
    case 'FIELD':
      return 'https://file.ynzyq.net/frontend/static/eae498c7250a3b2a05453b943267cd5f.svg';
    default:
      return 'https://file.ynzyq.net/frontend/static/f2bfb43b95022c53aefa646abac47bb2.svg';

    // case 'role':
    //   return require(`@/assets/images/insight360/visibleRange/role.png`);
  }
};
</script>

<style lang="scss" scoped>
.course-select-wrapper {
  width: 100%;
  border: 1px solid #c3c3c3;
  position: relative;
  padding: 12px 0px 0px 20px;
  color: #1d2129;
}
.selected-title {
  font-size: 12px;
}
.course-select-content {
  margin: 3px -4px -4px -4px;
  display: flex;
  height: 114px;
  overflow-y: overlay;
  flex-wrap: wrap;
  align-content: baseline;
  width: 100%;
}
.course-select-labels {
  width: max-content;
  padding: 3px;
  padding-right: 18px;
  border-radius: 2px;
  margin: 4px;
  height: 30px;
  background-color: #fff7f0;
  color: #ff6b00;
  display: flex;
  flex: 0 auto;
  position: relative;
  .course-select-labels-icon {
    img {
      width: 12px;
      height: 12px;
    }
    padding: 0 2px 0 2px;
  }

  .course-select-labels-text {
    margin-right: 4px;
    height: 24px;
    line-height: 24px;
  }
  .course-select-labels-close-wrapper {
    padding: 2px;

    cursor: pointer;
    .course-select-labels-close {
      font-size: 12px;
      // background: url('https://file.ynzyq.net/frontend/static/9afb737f871c4d0a1cbe75894e2ada20.svg') no-repeat
      //   100% 100%;
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
    }
  }
}
.course-select-clear {
  color: #ff4d4f;
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  right: 12px;
  width: max-content;
  padding: 4px;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .count {
    font-size: 12px;
    margin-bottom: 6px;
  }
}
</style>
