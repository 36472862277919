var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-upload"},[_c('div',{class:{ 'content-wrapper': true, custom: _vm.hasCustomMode }},[(_vm.hasCustomMode)?_c('div',{staticClass:"image-custom-background"},[_c('a-spin',{staticStyle:{"width":"100%","height":"100%"},attrs:{"spinning":_vm.customSpinning}},[_c('div',{staticClass:"custom-poster",attrs:{"id":"posterBg"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowTitle),expression:"isShowTitle"}],staticClass:"file-upload-title",style:({
              left: _vm.getImageTitlePosition.left,
              top: _vm.getImageTitlePosition.top,
            })},[_vm._v(" "+_vm._s(_vm.getCustomName)+" ")]),_c('img',{staticClass:"image-background",attrs:{"alt":"back","src":_vm.getImageBackgroundInfo.url}})])]),_c('div',{staticClass:"board-wrapper"},[_c('div',{staticClass:"switch",on:{"click":_vm.switchNextImage}},[_vm._v("换一张")]),_c('div',{staticClass:"refresh",on:{"click":_vm.switchNextImage}},[_vm._v("刷新")])])],1):_vm._e(),_c('a-upload',{attrs:{"disabled":_vm.disabled,"accept":_vm.accept,"list-type":_vm.listType,"show-upload-list":_vm.showUploadList,"beforeUpload":_vm.beforeImageUpload,"customRequest":_vm.customImageRequest}},[(!_vm.$scopedSlots.uploadBtn)?_c('div',{staticClass:"img-list"},[(!_vm.hasCustomMode)?_vm._l((_vm.imageUrls),function(item,index){return _c('div',{key:index,staticClass:"img-container",style:('width:' + _vm.width + ';height:' + _vm.height + ';padding:' + _vm.spacePadding)},[_c('img',{staticClass:"img",staticStyle:{"object-fit":"cover"},attrs:{"src":item}}),(_vm.tip)?_c('span',{staticClass:"tip"},[_vm._v(_vm._s(_vm.tip))]):_vm._e(),(_vm.getMultiple || _vm.tip)?_c('a-space',{staticClass:"options",on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[_c('a-icon',{attrs:{"type":"eye"},on:{"click":function($event){$event.stopPropagation();return _vm.showPrevew(index)}}}),(!_vm.disabled)?_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(index)}}}):_vm._e()],1):_vm._e()],1)}):_vm._e(),(
            _vm.hasCustomMode ||
            (_vm.getMultiple && _vm.imageUrls.length < _vm.getMaxCount && !_vm.disabled) ||
            (!_vm.getMultiple && !_vm.imageUrls.length)
          )?_c('div',{staticClass:"upload-btn",style:('width:' + _vm.width + ';height:' + _vm.height)},[(_vm.showTip)?_c('a-icon',{attrs:{"type":_vm.isShowSpinning ? 'loading' : 'plus'}}):_vm._e(),(_vm.showTip)?_c('div',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.tip || '点击上传图片')+" ")]):_vm._e()],1):_vm._e()],2):[_vm._t("uploadBtn",function(){return [_c('p',{staticClass:"default-tips"},[_vm._v("图片建议800*400，比例为2:1，支持jpg、png格式。")])]})]],2)],1),_c('a-modal',{attrs:{"title":"","visible":_vm.prevewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('span',{staticStyle:{"display":"inline-block"}},[_c('img',{staticStyle:{"width":"472px"},attrs:{"src":_vm.prevewUrl,"alt":""}})])]),_c('canvas',{staticStyle:{"display":"none"},attrs:{"id":"canvas"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }