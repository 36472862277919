import Request from '@/libs/request';
import { unpack } from '@/util/index';

import requestCommon from '@/libs/API_common.js';

const request = new Request('/api/flow');
const staffRequest = new Request('/om-auth/api');

// 添加反馈意见接口
export const addFeedback = (data) => {
  return request.post('/yn/surveyapp/feedback/add', data).then(unpack);
};
// 获取工作流列表
export const getWorkflowList = (params) => {
  return request.get('/template/list', { params }).then(unpack);
};
// 获取工作流详情
export const getWorkflowDetail = (params) => {
  return request.get('/template/detail', { params }).then(unpack);
};
// 保存或编辑工作流
export const saveWorkflow = (params) => {
  return request.post('/template/saveOrUpdate', params).then(unpack);
};
// 手动重发短信
export const resend = (params) => {
  return request.post('/notify/resend', params).then(unpack);
};
// 短信管控列表
export const smsList = (params) => {
  return request.get('/notify/list', { params }).then(unpack);
};
// 发布工作流
export const publishWorkflow = (params) => {
  return request.post('/template/publish', params).then(unpack);
};
// 停用工作流
export const stopWorkflow = (params) => {
  return request.post('/template/stop', params).then(unpack);
};

// 保存表单结构或者编辑
export const saveFormResult = (params) => {
  return request.post('/form/saveOrUpdate', params).then(unpack);
};

// 查询表单结构或者编辑
export const getFormResult = (params) => {
  return request.get('/form/detail', { params }).then(unpack);
};

// 保存单节点
export const saveNode = (params) => {
  return request.post('/node/save', params).then(unpack);
};
// 复制单节点
export const nodeCopy = (params) => {
  return request.post('/node/copy', params).then(unpack);
};
// 编辑单节点
export const editNode = (params) => {
  return request.post('/node/update', params).then(unpack);
};

// 节点详情 /node/detail
export const getNodeDetail = (params) => {
  return request.get('/node/detail', { params }).then(unpack);
};
// 判断是否内部人员
export const innerExternalUserSplit = (data) =>
  requestCommon({
    url: '/om-auth/api/auth/org/user/inner-external-user-split',
    method: 'post',
    // noLoading: true,
    data,
  });

// 删除单节点
export const deleteNode = (params) => {
  return request.post('/node/delete', params).then(unpack);
};

// 查询资料包列表
export const getMaterialList = (params) => {
  return request.post('/node/queryPathPreFields', params).then(unpack);
};

// 查询所有可驳回节点
export const getQueryRefuseNodes = (params) => {
  return request.get('/node/queryRefuseNodes', { params }).then(unpack);
};

// 查看所有节点列表 /node/queryPathPreNodes
export const getQueryPathPreNodes = (params) => {
  return request.get('/node/queryPathPreNodes', { params }).then(unpack);
};

// 查询待办列表 /handle/todoList
export const getTodoList = (params) => {
  return request.get('/handle/todoList', { params }).then(unpack);
};
// 查询待办列表 -聚合
export const getTodoListMerge = (params) => {
  return request.post('/handle/todoAggre', params).then(unpack);
};
// 查询待办列表 - 导出
export const exportTodoListMerge = (params) => {
  return request.post('/handle/todoAggreExport', params, { responseType: 'blob' });
};
export const taskTodoExport = (params) => {
  return request.post('handle/task-todo-list-export', params, { responseType: 'blob' });
};
export const taskDoneExport = (params) => {
  return request.post('handle/task-done-list-export', params, { responseType: 'blob' });
};
export const taskReadExport = (params) => {
  return request.post('handle/task-read-list-export', params, { responseType: 'blob' });
};
export const taskCcExport = (params) => {
  return request.post('handle/task-cc-list-export', params, { responseType: 'blob' });
};

// 创建项目 /instance/create
export const createProject = (params) => {
  return request.post('/instance/create', params).then(unpack);
};
// 项目列表/instance/list
export const getProjectList = (params) => {
  return request.get('/instance/list', { params }).then(unpack);
};
// 流程中止/恢复/作废原因查询接口
export const instanceReasonQuery = (params) => {
  return request.get('/instance/instanceReasonQuery', { params }).then(unpack);
};
// 流程中止上恢复/作废原因保存接口
export const instanceReasonSave = (params) => {
  return request.post('/instance/instanceReasonSave', params).then(unpack);
};
// 流程记录查询接口
export const instanceChangeRecordPage = (params) => {
  return request.get('/instance/instanceChangeRecordPage', { params }).then(unpack);
};
// 流程中止接口
export const instanceTermination = (params) => {
  return request.post('/instance/instanceTermination', params).then(unpack);
};
// 流程中止接口
export const instanceRecovery = (params) => {
  return request.post('/instance/instanceRecovery', params).then(unpack);
};

// 计算人数
export const countStaff = (params) => {
  return request.post('/template/countStaff', params).then(unpack);
};

// 待办详情/handle/todoDetail => /handle/captionGroup(增加分组)
export const getTodoDetail = (params) => {
  return request.get('/handle/captionGroup', { params }).then(unpack);
};
// 获取快照
export const getSnapshot = (params) => {
  return request.get('/handle/snapshot', { params }).then(unpack);
};

// 待办通过 /approval/approvalPass
export const approvalPass = (params) => {
  return request.post('/approval/approvalPass', params).then(unpack);
};

// 获取分类列表 /category/list
export const getCategoryList = (params) => {
  return request.get('/category/list', { params, noLoading: true }).then(unpack);
};
// 保存分类/category/saveOrUpdate
export const saveCategory = (params) => {
  return request.post('/category/saveOrUpdate', { ...params }, { noLoading: true }).then(unpack);
};
// 删除分类 /category/delete
export const deleteCategory = (params) => {
  return request.post('/category/delete', params).then(unpack);
};

// 获得转接人列表 /approval/getUserInfoList
export const getUserInfoList = (params) => {
  return request.get('/approval/getUserInfoList', { params }).then(unpack);
};

// 驳回功能 /approval/refuse
export const approvalRefuse = (params) => {
  return request.post('/approval/refuse', params).then(unpack);
};

// 转接功能 /approval/transfer
export const approvalTransfer = (params) => {
  return request.post('/approval/transfer', params).then(unpack);
};

// 处理记录列表 /handle/recordList
export const getRecordList = (params) => {
  return request.get('/instance/logs', { params }).then(unpack);
};

// 上传文件 /common/upload
export const uploadFile = (params) => {
  return request.post('/common/upload', params).then(unpack);
};

// 暂存，保存，重复提交 /approval/repeatedSubmit
export const repeatedSubmit = (params) => {
  return request.post('/approval/repeatedSubmit', params).then(unpack);
};

// 任务表单提交并完成 /approval/finishedSubmit
export const finishedSubmit = (params) => {
  return request.post('/approval/finishedSubmit', params).then(unpack);
};

// 删除任务模板 /template/delete
export const deleteTemplate = (params) => {
  return request.post('/template/delete', params).then(unpack);
};

// 根据角色或职位分页查询用户列表 /om-auth/api/auth/org/user/getByRoleOrPost
export const getStaffListByRoleOrPost = (params) => {
  return staffRequest.post('/auth/org/user/getByRoleOrPost', params);
};

// 查找组件的名称列表 /node/queryPathPreFieldsName
export const queryPathPreFieldsName = (params) => {
  return request.post('/node/queryPathPreFieldsName', params).then(unpack);
};

// 分支节点判断符枚举接口 /condition/queryJudgment
export const queryJudgment = (params) => {
  return request.get('/condition/queryJudgment', { params }).then(unpack);
};
// 获取分类统计列表 /instance/categories
export const getCategoryCountList = (params) => {
  return request.get('/instance/categories', { params }).then(unpack);
};

// 获取任务完成情况 /instance/nodes
export const getTaskCompleteList = (params, options) => {
  return request.get('/instance/nodes', { params, ...options }).then(unpack);
};

// 实例详情 /instance/detail
export const getInstanceDetail = (params) => {
  return request.get('/instance/detail', { params }).then(unpack);
};

// 实例统计 /instance/statistic
export const getInstanceStatistic = (params) => {
  return request.get('/instance/statistic', { params }).then(unpack);
};

// 获取资料包列表 /form/queryFormDataForInfoPackage
export const getQueryFormDataForInfoPackage = (params) => {
  return request.post('/form/queryFormDataForInfoPackage', params).then(unpack);
};

// 获取上传人员列表
export const getUploadStaffList = (params) => {
  return request.post('/form/queryFormDataCommitUser', params).then(unpack);
};

// 获取资料包详情上传节点 /node/queryForInfoPackage
export const getQueryForInfoPackage = (params) => {
  return request.post('/node/queryForInfoPackage', params).then(unpack);
};
// 审批记录列表 /handle/approvalLogs
export const getApprovalRecordList = (params) => {
  return request.get('/handle/approvalLogs', { params }).then(unpack);
};
// 审批记录列表全部 /handle/approvalLogAll
export const getApprovalRecordListAll = (params) => {
  return request.get('/handle/approvalLogAll', { params }).then(unpack);
};

/**发起子流程 */
// 获取子流程列表
export const getSubTemplateList = (params) => {
  return request.get('/instance/sub/templateList', { params }).then(unpack);
};
/**退单发起子流程 */
// 获取子流程列表
export const getLaunchableList = (params) => {
  return request.get('/instance/sub/launchableList', { params }).then(unpack);
};

// 发起子流程-获取表单
export const getLaunchForm = (params) => {
  return request.get('/instance/sub/getLaunchForm', { params }).then(unpack);
};
// 发起子流程-保存表单
export const createSubInstance = (params) => {
  return request.post('/instance/sub/create', params).then(unpack);
};

// 获取发起人列表
export const getCreatorList = (params) => {
  return request.get('/instance/creators', { params }).then(unpack);
};

/** 通知查询功能模块 */
// 通知内容查询 /notify/query
export const getNotifyQuery = (params) => {
  return request.get('/notify/query', { params }).then(unpack);
};
// 通知内容删除 /notify/delete
export const getNotifyDelete = (params) => {
  return request.post('/notify/delete', params).then(unpack);
};
// 通知内容保存 /notify/saveOrUpdate
export const getNotifySaveOrUpdate = (params) => {
  return request.post('/notify/saveOrUpdate', params).then(unpack);
};
// 通知内容的表单字段获取 /notify/getFieldList
export const getNotifyGetFieldList = (params) => {
  return request.post('/notify/getFieldList', params).then(unpack);
};

// 获取文件预览地址
export const getPreviewUrlReq = (params) => {
  return request.get('/common/getOfficePreviewURLRequest', { params }).then(unpack);
};

/** 开发者设置功能模块 */
// 获取开发者 /developer/detail
export const getDeveloperDetail = (params) => {
  return request.get('/developer/detail', { params }).then(unpack);
};
// 开发者设置保存 /developer/saveOrUpdate
export const getDeveloperSaveOrUpdate = (params) => {
  return request.post('/developer/saveOrUpdate', params).then(unpack);
};
// 开发者设置删除 /developer/delete
export const getDeveloperDelete = (params) => {
  return request.get('/developer/del', { params }).then(unpack);
};
// 查询主流程的所有审批任务节点 snapshotId必传
export const queryAllNodes = (params) => {
  return request.get('/node/queryAllNodes', { params }).then(unpack);
};

// 查询历史表单的详情数据
export const getHistoryDetailForm = (params) => {
  return request.post('/form/historyDetail', params).then(unpack);
};
// 保存公共字段
export const savePublicForm = (params) => {
  return request.post('/form/publicFormSave', params).then(unpack);
};
// 获取公共字段
export const getPublicForm = (params) => {
  return request.post('/form/obtainPublic', params).then(unpack);
};
// 获取节点列表
export const getNodeList = (params) => {
  return request.post('/instance/adminList', params).then(unpack);
};
// 获取节点数量
export const getNodeListCount = (params) => {
  return request.post('/instance/adminList/count', params).then(unpack);
};
// 获取节点列表 - 导出
export const exportNodeList = (params) => {
  return request.post('/instance/adminListExport', params, { responseType: 'blob' });
};
// 修改节点审批人/处理人
export const changeAssignedUser = (params) => {
  return request.post('/instance/reSetAssignUser', params).then(unpack);
};
// 修改空值处理
export const updatePreFieldsNullVisible = (params) => {
  return request.post('/node/updatePreFieldsNullVisible', params).then(unpack);
};

// 获取项目模板配置-项目监控
export const getInstanceTemplate = (params) => {
  return request.get('/instanceMonitor/getInstanceTemplate', { params }).then(unpack);
};
// 获取项目流程详情-项目监控
export const getInstanceTemplateInfo = (params) => {
  return request.get('/instanceMonitor/formDetail', { params }).then(unpack);
};
// 保存项目流程详情-项目监控
export const saveInstanceTemplateInfo = (params) => {
  return request.post('/instanceMonitor/formSave', params).then(unpack);
};

export const getStoreBuildTodoCount = (params) => {
  return request.get('/handle/todoNum', { params, noLoading: true }).then(unpack);
};

// 开店概览
// 阶段统计
export const getStageSummary = (params) => {
  return request.post('/board/stage/summary', params).then(unpack);
};
// 已开业门店统计
export const getOpenSummary = (params) => {
  return request.post('/board/open/summary', params).then(unpack);
};
// 落位用时曲线
export const getTimeLineLocation = (params) => {
  return request.post('/board/timeLine/location', params).then(unpack);
};
// 施工用时曲线
export const getTimeLineBuild = (params) => {
  return request.post('/board/timeLine/build', params).then(unpack);
};
// 设计用时曲线
export const getTimeLineDesign = (params) => {
  return request.post('/board/timeLine/design', params).then(unpack);
};
//项目预警
export const getWarnData = (params) => {
  return request.post('/board/warn/instance/page', params).then(unpack);
};
// 资料包打包下载
export const downloadAllFilesData = (params, options = {}) => {
  let onDownloadProgress =
    options && options.onDownloadProgress ? options.onDownloadProgress : () => {};
  return request.post('/form/downloadDataPackage', params, {
    responseType: 'blob',
    onDownloadProgress: onDownloadProgress,
    noLoading: true,
  });
};

// 获取项目阶段list
export const getCurrentStageList = (params) => {
  return request.get('/instance/obtainCurrentStage', { params }).then(unpack);
};
// 报表列表 /dataManagement/reportList
export const getReportList = (params) => {
  return request.post('/dataManagement/reportList', params).then(unpack);
};
// 报表列表 - 导出
export const exportReportList = (params) => {
  return request.post('/instance/export', params, { responseType: 'blob' });
};

// 报表配置详情 /dataManagement/reportConfig
export const getReportConfigDetail = (params) => {
  return request.get('/dataManagement/reportConfig', { params }).then(unpack);
};
// 报表配置默认字段 /dataManagement/defaultField
export const getReportConfigDefaultField = (params) => {
  return request.get('/dataManagement/defaultField', { params }).then(unpack);
};
// 报表配置流程字段 /dataManagement/flowField
export const getReportConfigFlowField = (params) => {
  return request.post('/dataManagement/flowField', params).then(unpack);
};
// 报表详情 /dataManagement/reportDetail
export const getReportDetail = (params) => {
  return request.post('/dataManagement/reportDetail', params).then(unpack);
};
// 报表配置保存 /dataManagement/reportSaveOrUpdate
export const saveReportConfig = (params) => {
  return request.post('/dataManagement/reportSaveOrUpdate', params).then(unpack);
};
// 删除报表 /dataManagement/reportDel
export const delReport = (params) => {
  return request.get('/dataManagement/reportDel', { params }).then(unpack);
};
// 导出报表 /dataManagement/export
export const getReportExport = (params) => {
  return request.post('/dataManagement/export', params, { responseType: 'blob' });
};

// 开店概览——light
// 已开业门店统计
export const getStoreStatusSummary = (params) => {
  return request.post('/board/storeStatus/summary', params).then(unpack);
};

// 门店排名
export const getStoreRank = (params) => {
  return request.post('/board/storeRank', params).then(unpack);
};

// 项目列表导出
export const exportProjectList = (params) => {
  return request.post('/instance/export', params, { responseType: 'blob' });
};

// 获得任务的二级分区
export const getTaskSecondLevelArea = (data) =>
  requestCommon({
    url: '/om-auth/api/auth/org/store/getAreaListByLevel',
    method: 'get',
    params: { ...data, level: 2 },
    noLoading: true,
  });

//获取部门列表
export const getOrgDeptList = (params) =>
  requestCommon({
    url: `/store-inspect/api/org/dept/list`,
    method: 'post',
    noLoading: true,
    data: params,
  });

//职位列表
export const getOrgPostList = (params) =>
  requestCommon({
    url: `/store-inspect/api/org/post/list`,
    method: 'post',
    noLoading: true,
    data: params,
  });

//获取角色列表
export const getRolePageList = (data) =>
  requestCommon({
    url: '/om-auth/api/auth/org/role/pageList',
    method: 'post',
    noLoading: true,
    data,
  });
// 获取当前用户是不是管理员
export const getIsAdminUser = (params) =>
  requestCommon({
    url: '/om-auth/api/auth/org/isAdminUser',
    method: 'get',
    noLoading: true,
    params,
  });

//人员列表
export const getOrgStaffList = (params) =>
  requestCommon({
    url: `/store-inspect/api/org/staff/list`,
    method: 'post',
    noLoading: true,
    data: params,
  });
//过滤权限-人员列表
export const getOrgStaffSubList = (params) =>
  requestCommon({
    url: `/store-inspect/api/org/staff/subList`,
    method: 'post',
    noLoading: true,
    data: params,
  });

//获取 项目监控中-项目的流程list
export const getProTemplateList = (params) => {
  return request.get('/instance/template/list', { params }).then(unpack);
};
// 获取 项目监控 -进度流程的某流程详情
export const getProTempDetail = (params) => {
  return request.get('/instance/template/detail', { params }).then(unpack);
};

// 手动调整项目节点状态-进行验证
export const verifyInstanceNode = (params) => {
  return request.post('/instance/verifyInstanceNode', params).then(unpack);
};

// 条件选择验证
export const verifyConditionNode = (params) => {
  return request.post('/instance/verifyConditionNode', params).then(unpack);
};

// 二次弹窗信息
export const getSkipNodeDetail = (params) => {
  return request.post('/instance/skip/node/secConfirm', params).then(unpack);
};

// 节点跳过验证
export const skipNode = (params) => {
  return request.post('/instance/skip/node', params).then(unpack);
};

// 查看干预详情
export const getSkipRecord = (params) => {
  return request.get('/instance/skip/record', { params }).then(unpack);
};

// 催办功能
export const expediting = (params) => {
  return request.get('/handle/expediting', { params }).then(unpack);
};

// 暂存
export const draftSave = (params, options = {}) => {
  return request.post('/approval/draft/save', params, options).then(unpack);
};
// 审批暂存
export const stageApprovalSave = (params) =>
  request.post('/approval/todo/save', params, { noLoading: true }).then(unpack);
export const getStageApproval = (params) =>
  request.get('/approval/todo/get', { params }).then(unpack);
// 获取省市区数据
export const getAreaData = () => {
  return request.get('/form/queryArea').then(unpack);
};

// 获取任务交接页面人员列表
export const getTaskTransferData = (params) => {
  return request.get('/handover/list', { params }).then(unpack);
};
// 任务交接页面人员列表 - 导出
export const exportTaskTransferData = (params) => {
  return request.post('/handover/export', params, { responseType: 'blob' });
};

// 获取人员对应的任务列表
export const getTaskListOfStaff = (params) => {
  return request.get('/handover/taskList', { params }).then(unpack);
};

// 任务交接
export const postTaskTransfer = (params) => {
  return request.post('/handover/taskHandover', params).then(unpack);
};

// 获取可更改暂停状态节点列表
export const getInstancePauseList = (params) => {
  return request.get('/instance/pausedList', { params }).then(unpack);
};

// 暂停/恢复节点
export const postInstanceUpdateStatus = (params) => {
  return request.post('/instance/updateStatus', params).then(unpack);
};
// ocr模板
export const getOcrTemplateOptions = (params) => {
  return request.get('/ocr/identification/format', { params }).then(unpack);
};
// ocr识别
export const ocrRecognition = (params) => {
  return request.post('/ocr/recognition', params).then(unpack);
};
// 关键词
export const ocrKeywordList = (params) => {
  return request.get('/ocr/keywordList', { params }).then(unpack);
};
// 判断是否为新公司
export const verifyBusiness = (params) => {
  return request.post('/ocr/verifyBusiness', params).then(unpack);
};
// 店长组件店长库数据
export const queryShop = (params) => {
  return request.get('/handle/queryShop', { params }).then(unpack);
};
// 店长组件店长库数据
export const assistantInputtips = (params) => {
  return request.get('/handle/search', { params }).then(unpack);
};
// 省市数据
export const getAllRegion = (params) => {
  return request.get('/region/getAllRegion', { params }).then(unpack);
};
// 流程实例更新记录查询接口
export const updateLogs = (params) => {
  return request.get('/instance/update/logs', { params }).then(unpack);
};
// 流程实例更新记录查询接口
export const retryUpdate = (params) => {
  return request.post('/instance/retryUpdate', params).then(unpack);
};
// 待办状态数量统计
export const todoCount = (params) => {
  return request.post('/handle/todoCount', params).then(unpack);
};
// 获取开店汇总数据接口
export const summaryv2 = (params) => {
  return request.post('/board/stage/summary/v2', params).then(unpack);
};
// 驳回节点下拉框
export const setNodeList = (params) => {
  return request.get('/node/setNodeList', { params }).then(unpack);
};
// 历史审批意见
export const opinionHistory = (params) => {
  return request.get('/handle/opinionHistory', { params }).then(unpack);
};
// 自定义审批意见
export const suggestAdvice = (params) => {
  return request.post('/handle/advice', params).then(unpack);
};
//新增编辑自定义审批意见
export const saveOrUpdateSuggest = (params) => {
  return request.post('/handle/saveOrUpdate', params).then(unpack);
};
//删除自定义审批意见
export const deleteSuggest = (params) => {
  return request.post('/handle/delete', params).then(unpack);
};
//保存组件关联数据
export const saveFormItemGanged = (params) => {
  return request.post('/form/saveFormItemGanged', params).then(unpack);
};
// 根据模板ID查询组件关联数据
export const queryFormItemGangedByTemplateId = (params) => {
  return request.get('/form/queryFormItemGangedByTemplateId', { params }).then(unpack);
};
// 根据代办ID查询组件关联数据
export const queryFormItemGangedByTaskRecordId = (params) => {
  return request.get('/form/queryFormItemGangedByTaskRecordId', { params }).then(unpack);
};
// 子流程排序
export const sortSubTemplate = (params) => {
  return request.post('/template/sortSubTemplate', params).then(unpack);
};
// 计算接口
export const evaluatorRPN = (params) => {
  return request
    .post('/investment/evaluatorRPN', params, {
      noLoading: true,
    })
    .then(unpack);
};
// 日期计算接口
export const evaluatorTime = (params) => {
  return request
    .post('/investment/evaluatorTime', params, {
      noLoading: true,
    })
    .then(unpack);
};

// 已阅列表
export const getTaskReadList = (params) => {
  return request.post('/handle/task-read-list', params).then(unpack);
};

// 待办列表
export const getTaskTodoList = (params) => {
  return request.post('/handle/task-todo-list', params).then(unpack);
};

// 已办列表
export const getTaskDoneList = (params) => {
  return request.post('/handle/task-done-list', params).then(unpack);
};

// 抄送列表
export const getTaskCcList = (params) => {
  return request.post('/handle/task-cc-list', params).then(unpack);
};

// 获取模板列表
export const getTemplateOptions = (params) => {
  return request.get('/template/main-template-options', params).then(unpack);
};
// 获取待办页面tab数量
export const getTaskTodoCount = (params) => {
  return request.post('/handle/task-todo-count', params).then(unpack);
};

// 任务详情
export const getTaskDetail = (params) => {
  return request.post('/handle/task-detail', params).then(unpack);
};
// 设置已读
export const taskSetReadFlag = (params) => {
  return request.post('/handle/task-set-read-flag', params).then(unpack);
};

// 任务详情
export const getTaskNum = (params) => {
  return request.post('/handle/get-task-num', params).then(unpack);
};

// 查询删除线
export const getTaskForbidItemFlag = (params) => {
  return request.post('/handle/get-task-forbid-item-flag', params).then(unpack);
};
// 公式计算-根据前端入参计算结果返回
export const calculateExpression = (params) => {
  return request.post('/form/calculate-expression', params).then(unpack);
};
// 获取接口字段
export const itemUseSituation = (params) => {
  return request.post('/form/item-use-situation', params, { noLoading: true }).then(unpack);
};
