<template>
  <!-- 此组件页面是用于开店待办处；其他处未做兼容；使用此组件时请注意 -->
  <div :class="['fast-form-wrapper', direction]">
    <a-form ref="form" :form="form" autocomplete="off" :label-align="labelAlign">
      <a-row :gutter="16">
        <a-col
          v-for="(item, index) in items"
          :key="index"
          :span="item.type === 'description' ? 24 : 12"
          v-show="isVisible(item)"
        >
          <fast-input-item
            :config="item"
            :key="index"
            @query="onQuery"
            :isRow="isRow"
            :formConfig="formConfig"
          >
            <template
              v-if="isObject(item.scopedSlots)"
              v-slot:[item.scopedSlots.customRender]="slotProps"
            >
              <slot :name="item.scopedSlots.customRender" v-bind="slotProps"></slot>
            </template>
          </fast-input-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import FastInputItem from './FastInputItem.vue';
import _ from 'lodash';
import useInitDataDispose from '@/pages/workflow/hooks/useInitDataDispose';
const { shopManagerValueChangeSetExpertValue } = useInitDataDispose();
export default {
  components: { FastInputItem },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    config: {
      type: Object,
      default: () => {
        return {
          direction: 'row',
          items: [
            {
              type: 'input',
              dataIndex: 'classify',
              placeholder: '请输入分类名称',
              width: 160,
              icon: {
                type: 'search',
                slot: 'suffix',
              },
            },
          ],
        };
      },
    },
  },
  methods: {
    isVisible(item) {
      return typeof item?.visible === 'boolean' ? item.visible : true;
    },
    getDataIndex(item) {
      return item.dataIndex;
    },
    onQuery() {
      this.$emit('query');
    },
    isObject() {
      return _.isObject(...arguments);
    },
    onChange(props, changedValues, allValues) {
      this.$nextTick(() => {
        this.$emit('change', allValues);
      });
    },
    // 验证表单并获得结果
    validateFields() {
      return this.form.validateFields();
    },
    // 清空表单值
    resetFields() {
      return this.form.resetFields();
    },
    // 获得全部字段或者某个字段的值
    getFieldsValue() {
      return this.form.getFieldsValue(...arguments);
    },
    // 设置某个字段的值
    setFieldsValue() {
      this.$nextTick(() => {
        this.form.setFieldsValue(...arguments);
      });
    },
  },
  computed: {
    items() {
      shopManagerValueChangeSetExpertValue(this.config.items, this.form);
      return this.config?.items || [];
    },
    formConfig() {
      const { labelWidth = null, colon = true } = this.config;
      // colon 加上之后样式会出现问题， 如果要加此属性，请注意修改样式
      // 目前办法是：.ant-form-item-label > label::after { visibility: hidden; }
      return { labelWidth };
    },
    // 表单对齐
    direction() {
      return this.config?.direction || 'row';
    },
    // label文字对齐
    labelAlign() {
      return this.config?.labelAlign || 'right';
    },
    isRow() {
      return this.direction === 'row';
    },
    form() {
      return this.$form.createForm(this, { onValuesChange: this.onChange });
    },
  },
};
</script>

<style lang="scss" scoped>
.fast-form-wrapper {
  & /deep/.ant-form {
    display: flex;
    // flex-wrap: wrap;
    justify-content: flex-start;
    // margin: -6px -14px -0px -14px;
    margin-top: -6px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.row /deep/.ant-form-item {
    margin-bottom: unset !important;
  }
  &.column {
    /deep/.ant-form {
      flex-direction: column;
    }
    /deep/.ant-form-item {
      margin-bottom: 12px;
    }
    /deep/ .ant-form-item-control-wrapper {
      margin-left: 16px !important;
    }
    .isDescription /deep/ .ant-form-item-control-wrapper {
      margin-left: 0 !important;
    }
    /deep/ .fast-input-item-wrapper {
      margin: 0;
    }
    /deep/ .form-item-label-box {
      display: inline;
    }
  }
  /deep/ .ant-form-item-label > label {
    color: #333;
    &::after {
      visibility: hidden;
      margin: 0 0px 0 0px;
      margin-right: -3.8px;
    }
  }

  /deep/ .ant-form-item-required::before {
    margin-right: 2px;
  }
}
</style>
