// 数据结构为：Map { 'event1' => [callback1, callback2], 'event2' => [callback1, callback2] }
const eventMap = new Map();

export default {
  data() {
    return {
      eventBusFunction: [],
    };
  },
  methods: {
    on(event, callback) {
      //将事件和回调函数存入数组，用于在组件销毁时删除事件
      this.eventBusFunction.push({ event, callback });

      //判断是否存在该事件
      if (!eventMap.has(event)) {
        eventMap.set(event, []);
      }
      //将事件回调函数存入数组
      eventMap.get(event).push(callback);
    },
    emit(event, ...args) {
      //判断是否存在该事件
      if (eventMap.has(event)) {
        eventMap.get(event).forEach((callback) => {
          // 使用vue的nextTick执行这些回调函数
          this.$nextTick(() => callback(...args));
        });
      }
    },
    off(event, callback) {
      //判断是否存在该事件
      if (eventMap.has(event)) {
        //判断是否传入回调函数
        if (callback) {
          //获取该事件的回调函数数组
          const callbacks = eventMap.get(event);
          //获取回调函数在数组中的索引
          const index = callbacks.indexOf(callback);
          //删除该回调函数
          callbacks.splice(index, 1);
        } else {
          //删除该事件的所有回调函数
          eventMap.delete(event);
        }
      }
    },
  },
  beforeDestroy() {
    this.eventBusFunction.forEach((item) => {
      this.off(item.event, item.callback);
    });
  },
};
