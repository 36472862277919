import qs from 'qs';
/**
 * 实时调用
 * 流程模板添加快照方法, 只能加必填的参数
 */
export default function getSnapshotParams() {
  const searchUrl = location.search.split('?')[1] || '';
  let searchParams = qs.parse(searchUrl); // 获取查询字符串
  let params = {};

  let querySnapshot = searchParams.isMonitor === undefined; // 只有项目管控有这个标识
  if (querySnapshot) {
    params.querySnapshot = true;
  }
  
  return params;
}
