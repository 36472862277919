<!-- 负责封装一些业务组件，以插槽的形式写入fast-form -->
<template>
  <div class="form-wrapper">
    <fast-form-Col ref="formData" @change="onChange" :config="formConfig" v-if="isCol">
      <template v-for="(_, slotName) in $scopedSlots" v-slot:[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps"></slot>
      </template>
      <template v-for="(_, slotName) in $slots" :slot="slotName">
        <slot :name="slotName"></slot>
      </template>
      <template #handle>
        <a-row :gutter="12">
          <a-col :span="8"> </a-col>
          <a-col v-if="cancel" :span="8">
            <a-button @click="cancel()">取消</a-button>
          </a-col>
          <a-col v-if="submit" :span="8">
            <a-button type="primary" @click="onSubmit()">确定</a-button>
          </a-col>
        </a-row>
      </template>
    </fast-form-Col>
    <fast-form ref="formData" @change="onChange" :config="formConfig" v-else>
      <template v-for="(_, slotName) in $scopedSlots" v-slot:[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps"></slot>
      </template>
      <template v-for="(_, slotName) in $slots" :slot="slotName">
        <slot :name="slotName"></slot>
      </template>
      <template #handle>
        <a-row :gutter="12">
          <a-col :span="8"> </a-col>
          <a-col v-if="cancel" :span="8">
            <a-button @click="cancel()">取消</a-button>
          </a-col>
          <a-col v-if="submit" :span="8">
            <a-button type="primary" @click="onSubmit()">确定</a-button>
          </a-col>
        </a-row>
      </template>
    </fast-form>
  </div>
</template>

<script>
import FastForm from '../../components-antd/MzFastForm/FastForm.vue';
import FastFormCol from '../../components-antd/MzFastForm/FastFormCol.vue';
import _ from 'lodash';
// 引入eventBusMixin
import eventBusMixin from '@/minxins/eventBus.js';
export default {
  components: { FastForm, FastFormCol },
  mixins: [eventBusMixin],
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    submit: {
      default: null,
    },
    cancel: {
      type: Function,
      default: null,
    },
  },
  computed: {
    formConfig() {
      const config = _.cloneDeep(this.config);
      config.labelAlign = config?.labelAlign ?? 'right';
      config.direction = config?.direction ?? 'column'; // 默认横向排列
      if (_.isFunction(this.submit) || _.isFunction(this.cancel)) {
        config?.items?.push({
          scopedSlots: {
            customRender: 'handle',
          },
          width: 400,
        });
      }
      return config;
    },
    isCol() {
      return this.config?.isCol;
    },
  },
  methods: {
    onKeyDown(e) {
      // 判断是否是回车键
      // if (e.keyCode === 13) {
      //   this.onSubmit();
      // }
    },
    onSubmit: _.debounce(
      function () {
        this.validateFields()
          .then((formData) => {
            this.submit?.(formData);
            this.$emit('submit', formData);
          })
          .catch((error) => {
            this.submit?.(error);
            this.$emit('submit', error);
          });
      },
      200,
      { leading: true, trailing: false },
    ),
    onChange(val) {
      this.$emit('change', val);
    },
    // 验证表单并获得结果
    validateFields() {
      return this.$refs.formData.validateFields();
    },
    // 清空表单值
    resetFields() {
      return this.$refs.formData.resetFields();
    },
    // 获得全部字段或者某个字段的值
    getFieldsValue() {
      return this.$refs.formData.getFieldsValue(...arguments);
    },
    // 设置某个字段的值
    setFieldsValue() {
      this.$nextTick(() => {
        this.$refs?.formData?.setFieldsValue(...arguments);
      });
    },
  },
  mounted() {
    // 为document绑定keydown事件
    const rootDom = window.document;
    rootDom.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    // 销毁document绑定的keydown事件
    const rootDom = window.document;
    rootDom.removeEventListener('keydown', this.onKeyDown);
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
}
</style>
