<template>
  <div class="header-container" :class="{ 'header-container-menu-fold': isCollapse }">
    <div class="lf">
      <div class="brand-logo">
        <a href="/insight360/">
          <img class="mz-logo" :src="mzLogo" />
        </a>
      </div>
      <div class="module-list">
        <div
          v-for="(module, index) in modules"
          :key="module.key"
          @click="handleClick(module)"
          class="menu-list__item"
          :class="{
            disabled: module.status != 1,
            'menu-list__item--active': module.id === currentModuleId,
          }"
        >
          {{ module.moduleName }}
        </div>
      </div>
    </div>

    <!--  标签-->
    <div class="menu-data" v-if="menuData.menuTab.length">
      <el-tabs
        v-model="menuData.menuIdB"
        type="card"
        :closable="menuData.menuTab.length > 1"
        @tab-remove="menuHandleClose"
        @tab-click="menuClickTab"
      >
        <el-tab-pane
          v-for="(item, index) in menuData.menuTab"
          :key="item.id"
          :label="item.name"
          :name="item.id"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div @click="openFeedback" class="feedback_btn">
      <img src="https://file.ynzyq.net/frontend/static/feedback_edit.png" alt="" />
      <span style="margin-left: 6px">反馈意见</span>
    </div>

    <div class="rt">
      <a-dropdown placement="bottomRight" :overlayStyle="{ zIndex: '9999999992' }">
        <div @click="(e) => e.preventDefault()" class="tanant-area">
          <img
            src="https://file.ynzyq.net/frontend/static/b53a6f00c96ef1182c49c739fc3efdab_296_296.png"
            alt=""
            class="tenant-logo"
          />
          <div class="name-and-role">
            <span class="user-name">{{ showUserNameAndTenant }}</span>
            <span class="user-name role-name">{{ showRole }}</span>
          </div>
          <a-icon class="down-icon" type="down" />
        </div>
        <tenentDrawer slot="overlay" />
      </a-dropdown>
    </div>

    <Feedback ref="feedback"></Feedback>
  </div>
</template>

<script>
import tenentDrawer from './components/tenentDrawer';
import Feedback from './Feedback.vue';
import { mapState } from 'vuex';
let timer = null;
let timer1 = null;

export default {
  name: 'mzHeader',
  props: {
    menuIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSetting: false,
    };
  },
  computed: {
    ...mapState({
      modules: (state) => state.menu.modules,
      currentMenu: (state) => state.menu.currentMenu,
      isCollapse: (state) => state.menu.isCollapse,
      menuData: (state) => state.menu.menuData,
    }),
    currentModuleId() {
      return this.currentMenu && this.currentMenu.moduleId ? this.currentMenu.moduleId : null;
    },

    showUserNameAndTenant() {
      const name = this.$store.state.tenant.userInfo.realName;
      const tenantName = this.$store.state.tenant.tenant.name;
      return `${name} - ${tenantName || ''} `;
    },
    showRole() {
      const rolesName = (this.$store.state.tenant.roles || []).map((i) => i.name).join(',');
      return `${rolesName || ''} `;
    },
    // tenantName() {
    //   return this.$store.state.tenant.tenant.name;
    // },
    mzLogo() {
      return this.isCollapse
        ? 'https://file.ynzyq.net/frontend/static/6729d50a977213b9f97cab6066fdef52_57_56.jpeg'
        : 'https://file.ynzyq.net/frontend/static/ce62a7945cbb2c57498f4088cfc6ac84_1014_172.png';
    },
  },
  components: {
    tenentDrawer,
    Feedback,
  },
  methods: {
    openFeedback() {
      this.$refs.feedback.open();
    },
    formatMenuField(menu) {
      return {
        id: menu.id + '',
        name: menu.name + '',
        path: menu.path + '',
      };
    },
    selectMenuItem(menu) {
      let formatMenuItem = this.formatMenuField(menu);
      let itemMenu = JSON.parse(JSON.stringify(formatMenuItem));
      let menuId = this.menuData.menuTab.map((item) => item.id);
      if (menuId.indexOf(itemMenu.id) == -1) {
        this.menuData.menuTab.push(itemMenu);
        localStorage.setItem('menuTabB', JSON.stringify(this.menuData.menuTab));
      }
      this.menuData.menuIdB = itemMenu.id;
      localStorage.setItem('menuIdB', itemMenu.id);
    },
    menuHandleClose(tag) {
      let index = this.menuData.menuTab.findIndex((item) => item.id == tag);
      this.menuData.menuTab.splice(index, 1);
      localStorage.setItem('menuTabB', JSON.stringify(this.menuData.menuTab));

      let selectIndex = index === 0 ? this.menuData.menuTab.length - 1 : index - 1;
      let selectItem = this.menuData.menuTab[selectIndex];

      this.menuData.menuIdB = selectItem.id;
      localStorage.setItem('menuIdB', selectItem.id);

      let url = selectItem.path;
      let query = selectItem.query ? selectItem.query : {};
      this.$router.push({
        path: url,
        query,
      });
      this.$forceUpdate();
    },
    menuClickTab(event) {
      let selectItem = this.menuData.menuTab[event.index];
      localStorage.setItem('menuIdB', selectItem.id);
      this.$router.push({
        path: selectItem.path,
        query: selectItem.query ? selectItem.query : {},
      });
    },
    // 点击模块
    handleClick(item) {
      this.$emit('change', item);
    },

    changeShow() {
      // if(this.loading) return
      this.showSetting && (this.showSetting = false);
      this.$emit('changeShow');
    },
    showModule() {
      timer = setTimeout(() => {
        this.$emit('showModule');
        // this.loading = true
        setTimeout(() => {
          // this.loading = false
        }, 500);
      }, 300);
    },
    clickShowSetting() {
      // clearTimeout(timer1)
      if (this.menuIsShow) {
        this.$emit('closeMenu');
      }
      this.showSetting = !this.showSetting;
    },
    handleShowSetting() {
      timer1 = setTimeout(() => {
        this.showSetting = true;
      }, 300);
    },
    handleMouseOver() {
      clearTimeout(timer);
      clearTimeout(timer1);
    },
    showConfig() {},
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixin.scss';
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 64px;
  width: 100%;
  box-shadow: 0px 2px 6px 0px #eff1fa;
  border-radius: 0px 0px 16px 16px;
  background: #ffffff;
  @include flex-mixin(row, center, space-between);
  .lf {
    @include flex-mixin(row, center, flex-start);
  }
  .rt {
    margin-left: 8px;
    border-left: 1px solid #e9edf4;
    .tanant-area {
      height: 64px;
      padding: 0 16px 0 8px;
      cursor: pointer;
      @include flex-mixin(row, center, space-between);

      .name-and-role {
        @include flex-mixin(column, flex-start, flex-start);
      }
      /deep/ .tenant-logo {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #e9edf4;
        padding: 2px;
        margin-right: 8px;
        border-radius: 50%;
      }

      &:hover {
        background-color: #ecf8ff;
        .down-icon {
          color: var(--active-color);
          transform: rotate(180deg);
        }
      }
    }

    .user-name {
      padding: 0 4px;
      max-width: 160px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #2a3950;
      line-height: 20px;
      margin-bottom: 4px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .role-name {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #6b7381;
      line-height: 17px;
    }
    .down-icon {
      margin-left: 4px;
      color: #6b7381;
      /* width: 16px; */
      font-size: 14px;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  .brand-logo {
    width: 200px;
    padding-left: 16px;
    height: 64px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background: #ffffff;
    border-bottom-left-radius: 16px;
    flex-shrink: 0;

    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .mz-logo {
    width: 177px;
    height: 32px;
  }

  .module-list {
    padding: 0 8px;
    @include flex-mixin(row, center, flex-start);
    overflow: auto;
    .menu-list__item {
      margin: 0 24px;
      height: 64px;
      line-height: 64px;
      position: relative;
      color: var(--menu-text-color);
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      flex-shrink: 0;
      cursor: pointer;
      &:hover {
        color: var(--active-color);
        font-weight: bold;
        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: #ff6b00;
          border-radius: 100px 100px 0px 0px;
        }
      }
    }
    .menu-list__item--active {
      color: var(--active-color);
      font-weight: bold;
      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: var(--active-color);
        border-radius: 100px 100px 0px 0px;
      }
    }
  }
}

.header-container-menu-fold {
  .brand-logo {
    width: 88px;
  }
  .mz-logo {
    width: 31px;
  }
}

::-webkit-scrollbar {
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba($color: #666, $alpha: 0.2);
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba($color: #fff, $alpha: 0.2);
}

.menu-data {
  flex: 1;
  width: 0;
}
.feedback_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 32px;
  border-radius: 4px;
  background: #fff5e8;
  box-sizing: border-box;
  border: 1px solid #ff6b00;
  color: #ff6b00;
  line-height: 1;
  cursor: pointer;
}
</style>
