import { render, staticRenderFns } from "./FastText.vue?vue&type=template&id=76394a07&scoped=true"
import script from "./FastText.vue?vue&type=script&setup=true&lang=js"
export * from "./FastText.vue?vue&type=script&setup=true&lang=js"
import style0 from "./FastText.vue?vue&type=style&index=0&id=76394a07&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76394a07",
  null
  
)

export default component.exports