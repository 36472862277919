<template>
  <div class="fast-input-wrapper">
    <!-- 插槽自定义组件 -->
    <template v-if="isSlots">
      <fast-slot v-bind="$attrs" :value="value" :config="config" v-on="$listeners">
        <template v-slot:[scopedSlots.customRender]="slotProps">
          <slot :name="scopedSlots.customRender" v-bind="slotProps"></slot>
        </template>
      </fast-slot>
    </template>
    <!-- 文本展示组件 -->
    <fast-text class="text-show" v-else-if="isText" :config="config" :value="value" v-bind="$attrs">
    </fast-text>
    <!-- 文字说明 展示组件 -->
    <fast-description v-else-if="isDescription" :config="config" :value="value"> </fast-description>
    <!-- 输入框组件 -->
    <div
      v-else-if="isInput"
      :style="{
        position: 'relative',
        marginBottom: maxLength != -1 ? '6px' : '0',
      }"
    >
      <a-input
        ref="input"
        :value="value"
        :addon-after="addonAfter"
        :placeholder="placeholder"
        :disabled="cannotHandle"
        allowClear
        v-on="$listeners"
        v-bind="$attrs"
        @blur="onBlur"
        @pressEnter="$emit('pressEnter')"
        :max-length="maxLength"
      >
      </a-input>
      <span
        v-if="maxLength != -1"
        style="position: absolute; right: 0; bottom: -26px; font-size: 12px; color: #909399"
      >
        {{ value && value.length ? value.length : 0 }} / {{ maxLength }}
      </span>
    </div>
    <!-- 地图选点组件 -->
    <!-- <input-map
      v-else-if="isInputMap"
      :value="value"
      v-on="$listeners"
      :disabled="cannotHandle"
    ></input-map> -->
    <!-- 搜索输入框组件 -->
    <a-input
      ref="input"
      v-else-if="isSearchInput"
      v-on="$listeners"
      v-bind="$attrs"
      :value="value"
      :placeholder="placeholder"
      allowClear
    >
      <a-icon slot="suffix" style="font-size: 18px" type="search" @click="emitQuery" />
    </a-input>
    <a-input
      v-else-if="isComputedExpression"
      ref="input"
      :value="value"
      :addon-after="addonAfter"
      :placeholder="placeholder"
      :disabled="cannotHandle"
      allowClear
      v-on="$listeners"
      v-bind="$attrs"
    >
    </a-input>
    <!-- 数字类型input -->
    <a-input-number
      v-else-if="isInputNumber"
      :min="inputNumberMin"
      :max="inputNumberMax"
      :placeholder="placeholder"
      :formatter="formatter"
      :parser="parser"
      :value="value"
      v-on="$listeners"
      v-bind="$attrs"
      style="width: 100%"
      :disabled="cannotHandle"
    />
    <!-- 密码类型input -->
    <a-input-password
      v-else-if="isInputPassword"
      v-on="$listeners"
      :value="value"
      v-bind="$attrs"
      placeholder="请输入密码"
    />
    <!-- button组件 -->
    <a-button
      :key="Date.now()"
      :loading="config?.loading"
      v-on="$listeners"
      :value="value"
      v-else-if="isButton"
      :type="buttonType"
      @click="onClick"
      >{{ text }}</a-button
    >
    <!-- 树形弹窗多选组件 -->
    <tree-select
      v-else-if="isTreeSelect"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      :config="config"
    ></tree-select>
    <!-- 一捆单选框组件 -->
    <a-radio-group
      v-else-if="isRadio && radioMode !== 'button'"
      :value="value"
      v-on="$listeners"
      v-bind="$attrs"
      :options="options"
      :disabled="cannotHandle"
    />
    <a-radio-group
      v-else-if="isRadio && radioMode === 'button'"
      :value="value"
      v-on="$listeners"
      v-bind="$attrs"
      @change="changeRadio"
    >
      <a-radio-button
        v-for="item in options"
        :key="item.value"
        :value="item.value"
        :disabled="item.disabled"
      >
        {{ item.label }}
      </a-radio-button>
    </a-radio-group>
    <!-- 上传文件-->
    <file-upload
      v-else-if="isFileUpload"
      v-on="$listeners"
      :value="value"
      v-bind="$attrs"
      :config="config"
    ></file-upload>
    <!-- switch开关 -->
    <fast-switch
      v-else-if="isSwitch"
      :disabled="cannotHandle"
      :value="value"
      v-on="$listeners"
      v-bind="$attrs"
      :default-checked="isDefaultChecked"
    >
    </fast-switch>

    <!-- 上传图片 -->
    <div v-else-if="isImageUpload">
      <image-upload
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        width="100px"
        height="100px"
        style="display: inline-block"
        :disabled="cannotHandle"
        :config="config"
      />
    </div>
    <!-- 文本域编辑 -->
    <div v-else-if="isTextarea" style="position: relative">
      <div v-if="config.addCommonReason">
        <a-popover v-for="item of optionCommonReason" :key="item.bizId">
          <template slot="content">
            <div style="max-width: 200px; word-break: break-word; white-space: normal">
              {{ item.content }}
            </div>
          </template>
          <a-tag
            style="cursor: pointer; background-color: #fff"
            closable
            @close="
              (e) => {
                handleDeleteSuggest(e, item);
              }
            "
            @click="addReason(item)"
            >{{ getSuggestText(item.content) }}</a-tag
          >
        </a-popover>
        <template v-if="optionCommonReason.length < 3">
          <a-tag
            style="cursor: pointer; background-color: #fff"
            @click="openReasonModal"
            v-if="!isEdit"
          >
            +添加常用意见
          </a-tag>
          <a-input
            ref="inputRef"
            :maxLength="200"
            v-else
            v-model="suggestReason"
            @blur="handleSaveOrUpdateSuggestNew"
            @keyup.enter="handleSaveOrUpdateSuggestNew"
            style="width: 200px; transform: translateY(-7px)"
          ></a-input>
        </template>
      </div>
      <a-textarea
        :placeholder="placeholder"
        :disabled="cannotHandle"
        v-bind="$attrs"
        :value="value"
        allowClear
        :auto-size="{ minRows: size, maxRows: size }"
        v-on="$listeners"
        :maxLength="textInputeLength"
      >
      </a-textarea>
      <span style="position: absolute; right: 0; bottom: -20px; font-size: 12px; color: #909399"
        >{{ value && value.length ? value.length : 0 }} / {{ textInputeLength }}</span
      >
    </div>
    <a-checkbox-group
      v-else-if="isCheckbox"
      v-bind="$attrs"
      v-on="$listeners"
      :options="options"
      :value="value"
      :disabled="cannotHandle"
    ></a-checkbox-group>
    <!-- 下拉搜索组件 -->
    <a-select
      v-else-if="isSelect"
      :mode="selectMode"
      :style="{ minWidth: '160px' }"
      :max-tag-count="maxTagCount"
      :value="selectMode == 'multiple' ? (!value ? [] : value) : value"
      v-bind="$attrs"
      v-on="$listeners"
      :allowClear="allowClear"
      :token-separators="[',']"
      :placeholder="placeholder"
      show-search
      option-filter-prop="children"
      :filter-option="filterOption"
      :disabled="cannotHandle"
    >
      <!-- 如果options是object[] -->
      <template v-if="isObject(options[0])">
        <a-select-option v-for="(item, index) in options" :value="item.value" :key="index">
          {{ item.label }}
        </a-select-option>
      </template>
      <!-- 如果options是string[] -->
      <template v-else>
        <a-select-option v-for="(str, index) in options" :value="str" :key="index">
          {{ str }}
        </a-select-option>
      </template>
    </a-select>
    <!-- 富文本组件 -->
    <fast-detail-editor
      v-bind="$attrs"
      :value="value"
      v-on="$listeners"
      v-else-if="isEditor"
    ></fast-detail-editor>
    <!-- 上传时间组件 -->
    <a-range-picker
      v-else-if="isRangePicker"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value || []"
      :style="{ width: contentWidth }"
      :show-time="showTime"
      :valueFormat="valueFormat"
      :placeholder="rangePlaceholder"
      :allowClear="allowClear"
    >
      <a-icon slot="suffixIcon" type="calendar"
    /></a-range-picker>
    <!-- 日期时间选择器 -->
    <a-date-picker
      v-else-if="isDatePicker"
      v-on="$listeners"
      v-bind="$attrs"
      :value="value"
      style="width: 100%"
      :valueFormat="valueFormat"
      :placeholder="dataPickerPlaceholder"
      :allowClear="allowClear"
    >
    </a-date-picker>
    <!-- 时间选择器 -->
    <a-time-picker
      v-else-if="isTimePicker"
      v-on="$listeners"
      :value="value"
      v-bind="$attrs"
      style="width: 100%"
      :valueFormat="valueTimeFormat"
      :placeholder="timePlaceholder"
      :allowClear="allowClear"
    >
    </a-time-picker>
    <!-- 人员选择组件-未来把 peopleSelect加进来 -->
    <template v-else-if="isPeopleVisible">
      <UserPicker class="people-list" :userList="value" v-bind="$attrs" v-on="$listeners">
        <a-button
          class="btn-people-picker"
          size="small"
          type="dashed"
          @click="peopleVisible = true"
          v-if="!disabled"
        >
          {{ text ?? '选择人员' }}
        </a-button>
      </UserPicker>

      <people-visible-range
        :visible.sync="peopleVisible"
        :value="value"
        :noFormSearch="false"
        v-bind="$attrs"
        v-on="$listeners"
      ></people-visible-range>
    </template>
    <!-- 省市区级联 -->
    <a-cascader
      v-else-if="isArea"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value || []"
      :options="optionsArea"
      :fieldNames="{
        label: 'name',
        value: 'name',
        children: 'child',
      }"
      :placeholder="areaPlaceholder"
    />
    <!-- 默认插槽 -->
    <div v-else style="display: none"></div>
  </div>
</template>

<script>
import SuggestionModal from './SuggestionModal.vue';
const inputLength = 200,
  textInputeLength = 1000;
import moment from 'moment';
import {
  TREE_SELECT,
  SELECT,
  INPUT,
  INPUT_MAP,
  EDITOR,
  BUTTON,
  RADIO,
  CHECKBOX,
  TEXTAREA,
  TEXT,
  RANGE_PICKER,
  DATE_PICKER,
  SEARCH_INPUT,
  COMPUTEDEXPRESS,
  SWITCH,
  FILE_UPLOAD,
  IMAGE_UPLOAD,
  TIME_PICKER,
  PEOPLE_SELECT,
  INPUT_NUMBER,
  INPUT_PASSWORD,
  AREA,
  DESCRIPTION,
} from './constants';
import _ from 'lodash';
import FastSlot from './FastSlot.vue';
import UserPicker from '@/components-antd/MzFastForm/UserPicker/index.vue';
import ImageUpload from '@/components-antd/MzFastForm/ImageUpload';
import TreeSelect from './TreeSelect.vue';
import FileUpload from './FileUpload.vue';
import PeopleVisibleRange from '@/components/PeopleVisibleRange/index.vue';
import FastText from './FastText.vue';
import FastDescription from './FastDescription.vue';
import FastDetailEditor from './FastDetailEditor.vue';
import FastSwitch from './FastSwitch.vue';
// import InputMap from './InputMap.vue';
import { getAreaData, suggestAdvice, saveOrUpdateSuggest, deleteSuggest } from '@/api/workflow';
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  components: {
    FastSlot,
    FastText,
    UserPicker,
    TreeSelect,
    FileUpload,
    PeopleVisibleRange,
    ImageUpload,
    FastDetailEditor,
    FastSwitch,
    // InputMap,
    FastDescription,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: 'select',
          dataIndex: 'classify',
          placeholder: '请输入分类名称',
          width: 160,
          icon: {
            type: 'search',
            slot: 'suffix',
          },
        };
      },
    },
    value: {
      default: undefined,
    },
    formConfig: {
      type: Object,
    },
    isRow: {
      type: Boolean,
    },
  },
  computed: {
    labelWidth() {
      let labelWidth = '0';
      if (this.isRow) {
        if (!_.isUndefined(this.config.label)) {
          // 分析出数字+字母的个数 分析出汉字的个数
          const reg = /[0-9a-zA-Z]/g;
          const regFont = /[\u4e00-\u9fa5]/g;
          const num = this.config.label.match(reg)?.length ?? 0;
          const numFont = this.config.label.match(regFont)?.length ?? 0;
          labelWidth = `${num * 8 + numFont * 14 + 24}px`;
        }
      } else {
        const width = this.config?.labelWidth ?? this.formConfig?.labelWidth ?? '';
        labelWidth = _.isNumber(width) ? width + 'px' : width;
      }

      return labelWidth;
    },
    contentWidth() {
      let width = this.config?.width ?? '';
      let rangeContentWidth = this.config?.rangeContentWidth ?? '';
      const contentWidth = _.isNumber(width) ? `${width}px` : width;
      if (this.isRow) {
        return this.isRangePicker ? rangeContentWidth : width ? `100%` : 'auto';
      } else {
        return width ? contentWidth : 'calc(100% - 120px - 20px)';
      }
    },
    label() {
      return this.config.label || '';
    },
    selectMode() {
      // a-select:mode(multiple,default,tags)
      return this.config?.mode || 'default';
    },
    // 判断逻辑开始
    isInput() {
      return this.config?.type === INPUT;
    },
    //是否为地图选点组件
    isInputMap() {
      return this.config?.type === INPUT_MAP;
    },
    // 是否为text组件类型
    isText() {
      return this.config?.type === TEXT;
    },
    isTextarea() {
      return this.config?.type === TEXTAREA;
    },
    isCheckbox() {
      return this.config?.type === CHECKBOX;
    },
    isSelect() {
      return this.config?.type === SELECT;
    },
    isEditor() {
      return this.config?.type === EDITOR;
    },
    isRangePicker() {
      return this.config?.type === RANGE_PICKER;
    },
    isDatePicker() {
      return this.config?.type === DATE_PICKER;
    },
    isDescription() {
      return this.config?.type === DESCRIPTION;
    },
    //是否显示时间
    showTime() {
      return typeof this.config?.showTime === 'boolean'
        ? this.config?.showTime
        : this.config?.showTime || {
            format: 'HH:mm:ss',
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
          };
    },
    isSwitch() {
      return this.config.type === SWITCH;
    },
    isImageUpload() {
      return this.config?.type === IMAGE_UPLOAD;
    },
    isTimePicker() {
      return this.config?.type === TIME_PICKER;
    },
    isPeopleVisible() {
      return this.config?.type === PEOPLE_SELECT;
    },
    isRadio() {
      return this.config?.type === RADIO;
    },
    isFileUpload() {
      return this.config?.type === FILE_UPLOAD;
    },
    isTreeSelect() {
      return this.config?.type === TREE_SELECT;
    },
    isSearchInput() {
      return this.config?.type === SEARCH_INPUT;
    },
    isComputedExpression() {
      return this.config?.type === COMPUTEDEXPRESS;
    },
    isButton() {
      return this.config?.type === BUTTON;
    },
    isInputNumber() {
      return this.config?.type === INPUT_NUMBER;
    },
    isInputPassword() {
      return this.config?.type === INPUT_PASSWORD;
    },
    isArea() {
      return this.config?.type === AREA;
    },
    isSlots() {
      return _.isObject(this.config.scopedSlots);
    },
    hasIcon() {
      return this.config?.icon?.type;
    },
    isVisible() {
      return typeof this.config?.visible === 'boolean' ? this.config.visible : true;
    },
    isShow() {
      return typeof this.config?.show === 'boolean' ? this.config.show : true;
    },
    // 判断逻辑结束
    options() {
      return this.config?.options || [];
    },
    // radio mode='button'类型
    radioMode() {
      return this.config?.radioMode;
    },
    disabled() {
      return (
        this.config?.disabled ||
        this.config?.hasDisabled ||
        this.config?.isNewCompanySwitch ||
        false
      );
    },
    cannotHandle() {
      return this.readonly || this.disabled || false;
    },
    readonly() {
      return this.config?.readonly || false;
    },
    size() {
      return this.config?.size ?? 4;
    },
    isDefaultChecked() {
      return this.config?.defaultChecked || false;
    },
    iconType() {
      return this.config?.icon?.type || '';
    },
    iconSuffix() {
      return this.config?.icon?.suffix || 'suffix';
    },
    buttonType() {
      return this.config.buttonType || 'primary';
    },
    dataIndex() {
      return this.config.dataIndex;
    },
    rules() {
      return this.config?.rules || [];
    },
    desc() {
      return this.config?.desc || '';
    },
    validateTrigger() {
      return this.config?.validateTrigger || 'change';
    },
    valueTimeFormat() {
      return this.config?.valueFormat ?? 'HH:mm:ss';
    },
    valueFormat() {
      return this.config?.valueFormat ?? 'YYYY-MM-DD HH:mm:ss';
    },
    initialValue() {
      return this.config?.initialValue;
    },
    placeholder() {
      return this.config?.placeholder || '';
    },
    dataPickerPlaceholder() {
      return this.config?.placeholder || '请选择日期';
    },
    //时间范围提示词
    rangePlaceholder() {
      return this.config?.rangePlaceholder || ['开始日期', '结束日期'];
    },
    // 使其范围提示词
    timePlaceholder() {
      return this.config?.placeholder || '选择时间';
    },
    areaPlaceholder() {
      return this.config?.placeholder || '选择省市区';
    },
    allowClear() {
      return this.config?.allowClear ?? true;
    },
    addonAfter() {
      return this.config?.addonAfter || false;
    },
    scopedSlots() {
      return this.config?.scopedSlots || {};
    },
    text() {
      return this.config.text;
    },
    // 非config值传
    decorator() {
      return [
        this.dataIndex,
        {
          validateTrigger: this.validateTrigger,
          rules: this.rules,
          initialValue: this.initialValue,
        },
      ];
    },
    itemsAlign() {
      const align = {};
      if (this.config?.align) {
        switch (this.config.align) {
          case 'right':
            align.marginLeft = 'auto';
            break;
          case 'left':
            align.marginRight = 'auto';
            break;
        }
      }
      return align;
    },
    // input maxLength
    maxLength() {
      // 限制200
      return this.config?.maxLength ?? -1;
    },
    // inputNumber formatter
    formatter() {
      return (
        this.config?.formatter ??
        function (res) {
          return res;
        }
      );
    },
    // inputNumber parser
    parser() {
      return (
        this.config?.parser ??
        function (res) {
          return res;
        }
      );
    },
    // inputNumber inputNumberMin
    inputNumberMin() {
      return this.config?.min ?? 1;
    },
    // inputNumber inputNumberMax
    inputNumberMax() {
      return this.config?.max ?? Infinity;
    },

    //select多选最多显示多少个 tag
    maxTagCount() {
      return this.config?.maxTagCount ?? 2;
    },
  },
  methods: {
    changeRadio(e) {
      // console.log('radio', e?.target?.value);
      // 临时兼容处理，由于切换tab没有触发请求
      this.$emit('change', e?.target?.value);
      this.$emit('query');
    },
    handleInputChange(event) {
      console.log('event', event);
      this.$emit('change', event?.target?.value);
    },
    isObject(arg) {
      return _.isObject(arg);
    },
    emitQuery() {
      this.$emit('query');
    },
    onClick() {
      if (this.config?.onClick) {
        this.$nextTick(() => {
          this.config.onClick();
        });
      }
    },
    onBlur() {
      if (this.config?.onBlur) {
        this.$nextTick(() => {
          // stepsFastForm中更新方法比较晚，sync allFormData中的值此时还没更新，暂时先用这种方法解决
          this.$nextTick(() => {
            this.config.onBlur(...arguments);
          });
        });
      }
    },
    //模糊搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async getCommonReason() {
      const { data } = await suggestAdvice();
      this.optionCommonReason = data;
    },
    async handleSaveOrUpdateSuggestNew() {
      try {
        this.isEdit = false;
        if (this.isHandlingEvent) {
          return;
        }
        this.isHandlingEvent = true;

        if (!this.suggestReason) {
          return;
        }
        await saveOrUpdateSuggest([...this.optionCommonReason, { content: this.suggestReason }]);
        this.getCommonReason();
        this.suggestReason = '';
      } finally {
        this.isHandlingEvent = false;
      }
    },
    addReason(item) {
      this.$emit('change', item.content);
    },
    async handleDeleteSuggest(e, item) {
      e.preventDefault();
      await deleteSuggest(item);
      this.getCommonReason();
    },
    openReasonModal() {
      this.isEdit = true;
      this.$nextTick(() => {
        this.$refs.inputRef.focus();
      });
    },
    getSuggestText(content) {
      return content.length > 10 ? `${content.slice(0, 10)}...` : content;
    },
  },
  data() {
    return {
      inputLength,
      textInputeLength,

      peopleVisible: false,
      optionsArea: [],
      optionCommonReason: [],
      suggestReason: '',
      isEdit: false,
      isHandlingEvent: false,
    };
  },
  created() {
    if (this.isArea) {
      getAreaData().then(({ data }) => {
        this.optionsArea = data;
      });
    }
    if (this.config?.type === TEXTAREA && this.config.addCommonReason) {
      this.getCommonReason();
    }
  },
};
</script>

<style lang="scss" scoped>
.fast-input-wrapper {
  flex: 1 0 auto;
  width: 100%;
}

.text-show {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.suggest-tag {
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  height: 22px;
  padding: 0 8px;
  margin-right: 8px;
  background-color: #fff;
}
.people-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .people-list {
    // min-height: 40px;
  }

  .btn-people-picker {
    // margin-left: 8px;
  }
}
</style>
